// src/components/InfoPage.js
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import BottomMenu from '../components/BottomMenu';
import Icon from '../components/Icon';
import {IonContent, IonFooter, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import './InfoPage.scss';

declare const _paq: any;
class InfoPage extends React.Component<any, any> {
    componentDidMount = () => {
        _paq.push(["setCustomUrl", "https://samtidsbild.se/info"]);
        _paq.push(["trackPageView", "Info"]);
    }
    render() {
        const appar = <div><h3>Använd våra appar!</h3><p>Det går också bra att använda våra appar, som finns hos Google Play eller App Store, för att ladda upp bilder.</p></div>;
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className="samtidsbild_header">Samtidsbild</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <div className="page infoPage">
                        <h3>Vad är samtidsbild?</h3>
                        <p>Samtidsbild är Stockholms läns museums digitala plattform för insamling av vår tids bilder. Alla som bor, jobbar eller har någon annan
                            anknytning till Stockholms län är välkomna att bidra.</p>
                        <p>Dagens bilder, oftast tagna med mobilkamera, är viktiga tidsdokument som dessvärre är flyktiga. Därför vill vi redan nu samla in dem för eftervärlden. För att vår fotosamling ska kunna berätta om oss alla måste samlingen innehålla en mångfald av berättelser och perspektiv från många människor. Hjälp oss att dokumentera livet i Stockholms län! Ladda upp bilder enligt eget val eller se vilka teman som är aktuella. Fotografierna lagras för framtiden i Stockholms läns museums digitala arkiv.</p>
                        <p>Vill du se alla samtidsbilder? Gå till denna sida: <a target="_blank" rel="noreferrer" href="https://stockholmslansmuseum.se/samlingar/samtidsbild/">Alla bilder.</a> Det tar ett dygn innan bilderna syns.</p>
                        {appar}
                        <h3>Stockholms läns museum</h3>
                        <p>Stockholms läns museum är ett regionalt museum med fokus på
                            kulturhistoria, kulturmiljö och konst. Alla är välkomna att ta del av vår
                            verksamhet. Vi finns på webben under adressen <a href="https://stockholmslansmuseum.se" target={"_blank"} rel={"noreferrer"}>stockholmslansmuseum.se</a></p>
                        <p>Har du frågor om Samtidsbild kontakta oss:<br/>
                            <a href="mailto:samtidsbild.kultur@regionstockholm.se">samtidsbild.kultur@regionstockholm.se</a>
                        </p>
                        <div className="footerlogo"><svg viewBox={"0 0 435.5 100"} className="icon" dangerouslySetInnerHTML={{__html: '<use xlink:href="#slmlogo"></use>'}}/></div>
                        <div style={{"fontSize": "0.4em", "position": "absolute", "bottom": "80px", "left":"10px"}}>Powered by <a href={"https://museum.link"} target={"_blank"} rel={"noreferrer"}>Museum.Link</a></div>
                        <br/><br/>
                    </div>
                </IonContent>
                <BottomMenu/>
            </IonPage>
        );
    }
}

export default withRouter(InfoPage)
