import React from 'react';
import './Termsofuse.scss';
interface ContainerProps {
    openTOU: any;
}
const Termsofuse: React.FC<ContainerProps> = ({openTOU}) => {
    return (
        <div className="tou">
            <div>
                <button className="close" onClick={openTOU}>Tillbaka</button>
                <h3>Användarvillkor</h3>
                <p>När du godkänner användarvillkoren har du och museet ett bindande avtal där följande ingår:</p>

                    <p>Fotografierna måste vara fotograferade av dig.</p>
                    <p>Du har upphovsrätten till ditt fotografi och får använda det som du vill men du delar rättigheter med Stockholms läns museum och andra genom att använda Creative Commons-licenser. Du väljer licens men vi rekommenderar: CC BY NC <div className={"ccbync"}/></p>
                    <p>Stockholms läns museum har rätt att använda dina bilder (t ex för utställningar, på webben, i sociala medier) samt lagra dem för framtiden. Bilderna får användas för historisk och vetenskaplig forskning.</p>
                    <p>Fotografierna kan komma att visas på andra sökportaler för museer som t ex europeana.eu</p>
                    <p>Som registrerad användare på Samtidsbild samtycker du till att ditt namn används i samband med dina uppladdade fotografier. Stockholms läns museum behandlar dina personuppgifter enligt rådande dataskyddslagstiftning. Du har rätt att få information om vilka personuppgifter vi behandlar om dig samt rätt att begära rättelse.</p>
                    <p>Om du är från 16 år och uppåt kan du registrera dig för att ladda upp bilder på Samtidsbild.</p>
                    <p>Du ansvarar själv för den text och de bilder du laddar upp.</p>
                    <p>Om det finns rättigheter, t ex varumärken på ditt foto ansvarar du för att du får ladda upp bilden på Samtidsbild.</p>
                    <p>Tänk på att fota människor på ett schyst sätt och fråga gärna om de vill vara med på bild på internet.</p>
                    <p>Om du vill avsluta ditt medlemskap på Samtidsbild kontakta Stockholms läns museum.</p>
                    <p>Stockholms läns museum har rätt att ta bort fotografier sombryter mot användarvillkoren. Fotografier som kan upplevas kränkande eller vara i strid med svensk lagstiftning kommer att tas bort från Samtidsbild.</p>
                    <p>Stockholms läns museum har rätt att gallra i det uppladdade materialet.</p>

                <h3>DE FINSTILTA PARAGRAFERNA...</h3>
                <p>§ 1 Samtidsbild är en elektronisk anslagstavla dvs. en tjänst för elektronisk förmedling av meddelanden som drivs av Stockholms läns museum. Användarvillkoren har motsvarande definitioner av meddelanden som anges i § 1 Lag (1998:112) om ansvar för elektroniska anslagstavlor. Det som publiceras på Samtidsbild räknas som meddelanden, bland annat de fotografier och texter som laddas upp av användare. Användarens meddelanden är tillgängliga för alla andra användare.</p>
                <h3>Upphovsrätt, licensiering och publicering</h3>
                <p>§ 2 Stockholms läns museum önskar att det ska vara så fritt som möjligt att använda de uppladdade fotografierna, både för museet och andra. Genom att licensiera fotografiet med Creative Commons-licens anger Användaren hur det får användas. Stockholms läns museum rekommenderar CC-BY eller CC-BY-NC. CC-BY betyder att fotografiet får återanvändas fritt men Användaren måste anges som upphovsman. CC-BY-NC betyder att fotografiet får återanvändas, att Användaren måste anges som upphovsman och att fotografiet intefår publiceras i kommersiella sammanhang.</p>
                <p>§ 3 Genom att Användaren bidrar med meddelanden till Samtidsbild ger Användaren en icke-exklusiv användningsrätt till Stockholms läns museum att, med nedan angivna begränsningar, framställa exemplar samt på annat sätt göra meddelanden tillgängliga för allmänheten. Stockholms läns museum äger även rätt att förlägga publiceringen till annans webbsajt, på sociala medier eller sökportal på Internet. Inget i dessa paragrafer medför inskränkningar i Användarens rätt att använda sina meddelanden på egen hand. Ersättning utgår inte för Användarens arbete eller Stockholms läns museums rätt att använda Användarens meddelanden enligt användarvillkoren för Samtidsbild och dessa paragrafer. Stockholms läns museum ansvarar inte för hur tredje part använder meddelanden.</p>
                <p>§ 4 Användaren ansvarar för att han/hon är upphovsman till sina meddelanden.</p>
                <p>§ 5 Överlåter Användaren upphovsrätten till ett meddelande till någon annan, ska Användaren alltid göra förbehåll för Stockholms läns museums användningsrättigheter i enlighet med användarvillkoren för Samtidsbild och dessa paragrafer.</p>
                <h3>Personuppgifter</h3>
                <p>§ 6 Användarvillkoren och dessa paragrafer har motsvarande definition av personuppgifter som anges i dataskyddsförordningen, General Data Protection Regulation (2016/679).</p>
                <p>§ 7 Användaren accepterar genom att registrera sig samt godkänna användarvillkoren och dessa paragrafer att Stockholms läns museum registrerar de personuppgifter som efterfrågas vid registreringen. Stockholms läns museum förbinder sig att behandla personuppgifter och annan information i enlighet med rådande dataskyddslagstiftning.</p>
                <p>§ 8 Användaren ska inte lägga ut privat information om sig själv eller andra på Samtidsbild. Personuppgifter får inte läggas ut på Samtidsbild om det strider mot dataskyddslagstiftningen.</p>
                <p>§ 9 Behandlingen av det insamlade materialet sker på laglig grund och med stöd av ett allmänt intresse, enligt artikel 6e i dataskyddsförordningen.</p>
                <p>§ 10 I enlighet med gällande dataskyddslagstiftning har du rätt att få tillgång till information om vilka personuppgifter som vi behandlar om dig samt rätt att begära rättelse av dina personuppgifter. Läs mer om hur Stockholms läns museum hanterar personuppgifter i museets integritetspolicy.</p>
                <h3>Arkivering och publicering</h3>
                <p>§ 11 Genom att meddelanden och personuppgifter lämnas in på Samtidsbild blir de del av Stockholms läns museums kulturhistoriska arkiv och kommer att bevaras och förvaltas för kommande generationer och för historiska och vetenskapliga forskningsändamål.</p>
                <h3>Urval och gallring</h3>
                <p>§ 12 Stockholms läns museum förbehåller sig rätten att göra urval i inkomna meddelanden. Målsättningen är dock att samtliga meddelanden skall arkiveras, men till exempel sparas inte meddelanden som har olämpligt innehåll som strider mot lagen.</p>
                <p>§ 13 Personuppgifter och annat material kan komma att gallras om de inte längre bedöms som relevant att bevara.</p>
                <h3>Olämpligt innehåll</h3>
                <p>§ 14 Användaren skall inte använda Samtidsbild för att utföra eller uppmana till brott eller sprida meddelanden som på annat sätt är olämpliga. Meddelanden på Samtidsbild får till exempel inte innehålla:</p>
                <ul>
                    <li>hets mot folkgrupp, vilket innebär att man uttrycker förakt eller hotar en grupp på grund av till exempel dess etniska bakgrund, hudfärg, sexuella läggning eller religion.</li>
                    <li>uppvigling, vilket innebär att i ord eller skrift uppmana andra till brott eller ohörsamhet mot myndighet.</li>
                    <li>barnpornografi, pornografi samt bilder eller annat material där någon utsätts för sexuellt våld eller tvång.</li>
                    <li>immaterialrättsligt skyddat material utan rättsinnehavarens tillstånd, vilket inkluderar upphovsrätt, varumärken, andra kännetecken, patenträtt och mönsterrätt.</li>
                    <li>förtal, förolämpning, diskriminering, hot, kränkande fotografering eller något annat som kränker andra människors integritet eller rättigheter.</li>
                </ul>
                <p>§ 15 Användaren skall inte använda Samtidsbild för annonsering, marknadsföring eller spam.</p>
                <h3>Stockholms läns museums rätt att vidta åtgärder</h3>
                <p>§ 16 Borttagning/döljande av meddelande kan ske om ett meddelande strider mot svensk lag, förordning, användarvillkoren för Samtidsbild eller dessa paragrafer eller om Stockholms läns museum på annat sätt finner meddelandet olämpligt. Stockholms läns museum har även rätt att med omedelbar verkan säga upp Användarens användarbehörighet om det framkommer att Användare använt Samtidsbild i strid med användarvillkoren eller dessa paragrafer. Beslut om att ta bort eller dölja ett meddelande, eller att säga upp en Användares användarbehörighet, behöver inte motiveras.</p>
                <h3>Tvist</h3>
                <p>§ 17 Tvist om tolkning eller tillämpning av användarvillkoren för Samtidsbild eller dessa paragrafer ska i första hand lösas genom förhandling mellan parterna. Om parterna trots upprepade förhandlingar inte kan komma överens ska tvist om tolkning av tillämpning av användarvillkoren för Samtidsbild eller dessa paragrafer och därmed sammanhängande rättsfrågor ska det avgöras av allmän domstol med tillämpning av svensk rätt.</p>
                <h3>Övrigt</h3>
                <p>§ 18 Användarvillkoren för Samtidsbild och dessa paragrafer kan komma att ändras. Vid en ändring kommer användarna att informeras genom ett meddelande på Samtidsbild.</p>
                <p>§ 19 Om Användaren vill avsluta sin användarbehörighet på Samtidsbild ska Användaren kontakta Stockholms läns museum. Om Användaren så önskar har Användaren rätt att få sina meddelanden avpublicerade. Dessa meddelanden och personuppgifter kommer dock att finnas kvar i Stockholms läns museums arkiv och kan fortsatt komma att användas i forskning. Innehåll som publicerats kan finnas kvar på annans webbplats eller sökportal på Internet.</p>
                <p>
                    <b>Kontakta Stockholms läns museum</b>
                    <br/>
                    E-post: <a href="mailto:samtidsbild.kultur@regionstockholm.se">samtidsbild.kultur@regionstockholm.se</a>
                </p>
                <b>Villkoren är uppdaterade 2018-05-25</b>
                <br/><br/>
                <button className="close" onClick={openTOU}>Tillbaka</button>
            </div>
        </div>
    );
}
export default Termsofuse;
