// src/components/Icon.js
import React from 'react';
import './Icon.scss';

interface ContainerProps {
    name: any;
}
const Icon: React.FC<ContainerProps> = ({name}) => {

    const svg ='<use xlink:href="#'+ name +'"></use>';
    return (
        <svg  height="100%" viewBox={"0 0 435.5 435.5"} className="icon" dangerouslySetInnerHTML={{__html: svg}}/>
    )
}

export default Icon;
