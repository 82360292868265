import $ from 'jquery';
const _store = {};

class Auth {
    login = (email = "", password = "", then: any, error: any) => {
        if(email == "")
        {
            const em = localStorage.getItem('email');
            if (!em) { return; } else { email = em;}
        }
        if(password == "")
        {
            const pw = localStorage.getItem('password');
            if (!pw) { return; } else { password = pw;}
        }

        console.log("Auth Login", email);
        const url = "https://api.samtidsbild.museum.link/login";

        $.ajax({
            type: "POST",
            crossDomain: true,
            url: url,
            dataType: 'json',
            cache: false,
            data: {"email": email, "password": password},
            success: function (data: any) {
                console.log("DATA: ",data);
                if(data.ok)
                {
                    localStorage.setItem('authToken', data.authToken);
                    localStorage.setItem('email', email);
                    localStorage.setItem('password', password);
                    const now = Math.floor((new Date).getTime() / 1000)+ 60 * 60 * 2;
                    localStorage.setItem("valid_time", now.toString());
                    then();
                }
                else
                {
                    //console.log("Wrong login try again!");
                    error();
                }

            }.bind(this),
            error: function(xhr: any, status: any, err: any) {
                console.error(url, status, err.toString());
            }.bind(this)
        });
    }

    checkValid(then: any) {
        const v_time = localStorage.getItem('valid_time');
        if(v_time) {
            const valid_time = new Date(v_time);
            const now = Math.floor((new Date).getTime() / 1000);

            //console.log(valid_time, now);
            if(valid_time.getTime() > now)
            {
                //console.log("Still valid");
                then();
            }
            else
            {
                //console.log("Old, login again!");
                this.login("","",then, null);
            }
        }
    }
}
const auth = new Auth();
export default auth
