// src/components/BottomMenu.js
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import Icon from './Icon';
import FileContainer from '../library/FileContainer';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { Device } from '@capacitor/device';
import "./BottomMenu.scss";
import {IonFooter} from "@ionic/react";
import {Directory, Encoding, Filesystem} from "@capacitor/filesystem";

class BottomMenu extends React.Component<any, any> {
    public readonly state: any = {
        showUploadModal: false,
    }

    logDeviceInfo = async () => {
        const info = await Device.getInfo();

        console.log(info);
    };

    constructor(props: any) {
        super(props);
        this.logDeviceInfo();
    }

    /*constructor(props) {
        super(props);

        this.state = {
            showUploadModal:false
        };
        console.log(FileContainer);
    }*/

    uploadModal = () => {
        this.setState({showUploadModal:!this.state.showUploadModal});
    }
    checkIfSame() {
        if(window.location.pathname == "/upload/album" || window.location.pathname == "/upload/camera") {
            //browserHistory.replace(window.location.pathname);
            // Router.refresh();
        }
    }
    pickFile = () => {
        FilePicker.pickImages({
            multiple: false,
            readData: true
        }).then((result) => {
            if(result && result.files && result.files.length > 0) {
                //console.log("Selected file: ", result);
                //const fc  = new FileContainer();
                //fc.setFile(result.files[0]);
                //console.log("FileContainer: ", fc, fc.getFile(), result.files[0].size);

                const history = this.props.history;
                /*if(result.files[0].path) {
                    Filesystem.readFile({
                        path: result.files[0].path,
                        directory: Directory.Documents,
                        encoding: Encoding.UTF8,
                    }).then((file: any) => {
                        console.log("Read file from Filesystem: ", file);
                    })
                }*/
                this.uploadModal();
                history.replace('/upload/album', {file: result.files[0]});
            }
        })
    }
    setFile(e: any) {
        console.log("SetFile",e);

        if (e.target.files && e.target.files[0]) {
            //var form = home.state.form;
            //form.timestamp = this.getTimestamp();
            //const fc  = new FileContainer();
            //fc.setFile(e.target.files[0]);
            const history = this.props.history;
            history.replace('/upload/album');
            //console.log("FileContainer: ", fc, fc.getFile());

            /*this.setState({file: e.target.files[0],form:form}, function() {
                console.log(this.state.file);
                var reader = new FileReader();
                reader.onload = function (e) {
                    home.setState({preview: e.target.result}, null);

                    var image = new Image();
                    image.addEventListener("load", function () {
                        //console.log(image.width, image.height);
                        home.setState({imagething:{width: image.width, height: image.height, offsetWidth: image.offsetWidth, offsetHeight: image.offsetHeight}},null);
                        image = null;
                    });
                    image.src = e.target.result;
                };
                reader.readAsDataURL(this.state.file);
                home.nextPage();
                //up.openGuide();;
                EXIF.getData(this.state.file, function() {
                    var allMetaData = EXIF.getAllTags(this);
                    var form = home.state.form;
                    if(allMetaData.DateTimeOriginal != null)
                    {
                        form.timestamp = allMetaData.DateTimeOriginal;
                    }
                    if(allMetaData.gpsposition != null)
                    {
                        //todo parse the GPS thing
                        //todo this.setGPS(parsed Cordinates);
                    }
                    //console.log(allMetaData);
                    home.setState({EXIF: allMetaData, form:form},null);
                    //var allMetaDataSpan = document.getElementById("allMetaDataSpan");
                    //allMetaDataSpan.innerHTML = JSON.stringify(allMetaData, null, "\t");
                });

            });*/
        }
    }

    render() {
        let uploadModal = null;
        let backgroundToModal = null;
        if(this.state.showUploadModal)
        {
            //onClick={this.checkIfSame.bind(this)}
            uploadModal = <div className="uploadModal">
                <label><div onClick={this.pickFile}>Välj befintlig bild</div></label>
                {/*<label htmlFor={"file"}><div >Välj gammal bild</div></label>*/}
                <Link to="/upload/camera" replace={true} onClick={this.uploadModal}><div>Ta ny bild</div></Link>
            </div>;
            backgroundToModal = <div className="uploadModalBackground" onClick={this.uploadModal.bind(this)}/>
        }
        //const plus = <label htmlFor="file"><div className="plus"><Icon name={"contribute01"}/></div></label>;
        const plus = <div className="plus" onClick={this.uploadModal}><Icon name={"contribute01"}/></div>;
        //+(window.location.pathname.indexOf("/upload") > -1 ? "01" : "")
        //console.log("TYPEOF DEVICE:", typeof device);
        /*if(typeof device != "undefined")
        {
            if(device.platform == "Android")
            {
                plus = <div className="plus" onClick={this.uploadModal.bind(this)}><Icon name={"contribute01"}/></div>
            }
        }
        else {
            //console.log("Device is undefined");
        }*/
        /* +(window.location.pathname.indexOf("/upload") > -1 ? "01" : "") */

        let content =
            <div className="bottomMenuContainer">
            <IonFooter>
                <div className="bottomMenu">
                    <input type="file" name="file" accept="image/*;capture=camera" id="file" style={{"position": "absolute", "left": "100%", "visibility": "hidden"}} onChange={this.setFile.bind(this)} />
                    <Link to="/album" replace={true}><div className="home"><Icon name={"home01"+(window.location.pathname == "/album" ? "01" : "")}/></div></Link>
                    <Link to="/about" replace={true}><div className="question"><Icon name={"question01"+(window.location.pathname == "/about" ? "01" : "")}/></div></Link>
                    {plus}
                    <Link to="/profile" replace={true}><div className="profile"><Icon name={"profile01"+(window.location.pathname == "/profile" ? "01" : "")}/></div></Link>
                    <Link to="/info" replace={true}><div className="info"><Icon name={"info01"+(window.location.pathname == "/info" ? "01" : "")}/></div></Link>
                </div>
            </IonFooter>
            {backgroundToModal}
            {uploadModal}
            </div>
        ;

        if(localStorage.getItem('authToken') == null) {
            content =
            <div className="bottomMenuContainer">
                <IonFooter>
                    <div className="bottomMenu">
                        <Link to="/welcome" replace={true}><div className="home"><Icon name={"home01"+(window.location.pathname == "/album" ? "01" : "")}/></div></Link>
                        <Link to="/about" replace={true}><div className="question"><Icon name={"question01"+(window.location.pathname == "/about" ? "01" : "")}/></div></Link>
                        <Link to="/info" replace={true}><div className="info"><Icon name={"info01"+(window.location.pathname == "/info" ? "01" : "")}/></div></Link>
                    </div>
                </IonFooter>
            </div>
        }
        return (
            content
        );
    }
}

export default withRouter(BottomMenu)
