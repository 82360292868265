import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import BottomMenu from '../components/BottomMenu';
import Icon from '../components/Icon';
import $ from 'jquery';
import Auth from '../library/Auth';
import { IonContent, IonHeader, IonPage, IonTitle, IonFooter, IonToolbar } from '@ionic/react';
import './AlbumPage.scss'

declare const _paq: any;
class AlbumPage extends React.Component<any, any> {
    public readonly state: any = {
        pics: [],
        openFullscreen: false,
        currentFullscreen: 0
    }

    componentDidMount() {
        this.getPics();
        _paq.push(["setCustomUrl", "https://samtidsbild.se/album"]);
        _paq.push(["trackPageView", "Album"]);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if(prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname === this.props.match.path) {
            this.componentDidMount();
        }
    }

    getPics() {
        console.log("GetPics!");
        const authToken = localStorage.getItem('authToken');
        const email = localStorage.getItem('email');
        //email = "Ann-Sofie%20Nygren";
        const url = "https://api.samtidsbild.museum.link/list?authToken="+authToken+"&q="+email;
        Auth.checkValid(() => {
            $.ajax({
                url: url,
                type: "GET",
                crossDomain: true,
                cache: false,
                success: (data: any) => {
                    console.log("List of images: ", data);
                    this.setState({pics: data});
                },
                error: (xhr: any, status: any, err: any) => {
                    console.error(url, status, err.toString());
                }
            });
        });
    }

    openFullscreen(index: number) {
        this.setState({openFullscreen: true, currentFullscreen: index});
    }
    closeFullscreen() {
        this.setState({openFullscreen: false});
    }
    previousImage() {
        //console.log(this.state.currentFullscreen);
        if(this.state.currentFullscreen > 0) {
            //console.log("Going to previous image");
            this.setState({currentFullscreen: this.state.currentFullscreen-1}, () => {
                console.log(this.state.currentFullscreen, this.state.pics[this.state.currentFullscreen])
            });
        }
    }
    nextImage() {
        //console.log(this.state.currentFullscreen, this.state.pics.length-1);
        if(this.state.currentFullscreen < this.state.pics.length-1) {
            //console.log("Going to next image");
            this.setState({currentFullscreen: this.state.currentFullscreen+1}, () => {
                console.log(this.state.currentFullscreen, this.state.pics[this.state.currentFullscreen])
            })
        }
    }

    render() {
        const pictures = [];
        //var pics = 5;
        let i;
        for(i = 0; i < this.state.pics.length; i++)
        {
            const pic = this.state.pics[i];
            // console.log(pic.thumbnail_display_urls);
            if(pic.thumbnail_display_urls != null)
            {
                //for(var j = 0; j < pic.representations.length; j++)
                //{
                if(pic.thumbnail_display_urls.square) {
                    pictures.push(<div key={"pic"+i+0} className="picture" style={{backgroundImage: "url("+pic.thumbnail_display_urls.square.replace('http://', 'https://')+")"}} onClick={this.openFullscreen.bind(this, i)}/>);
                }
                //}
            }
        }
        let emptyframestoadd = pictures.length % 3;
        if(emptyframestoadd > 0)
        {
            emptyframestoadd = 3 - emptyframestoadd;
        }
        for(i = 0; i < emptyframestoadd; i++)
        {
            pictures.push(<div key={"emptypic"+i} className="picture"/>);
        }

        const currentpic = this.state.pics[this.state.currentFullscreen];

        const fullscreenImage = (this.state.openFullscreen ?
            <div className={"fullscreenImage"}>
                {/*<div className={"img"} style={{backgroundImage: "url("+this.state.pics[this.state.currentFullscreen].representations[1]+")"}}></div>*/}
                <img className={"img"} src={currentpic.thumbnail_display_urls.large.replace('http://', 'https://')}/>
                <div className={"leftBox"} onClick={this.previousImage.bind(this)} style={{display: (this.state.currentFullscreen === 0 ? "none":"block")}}/>
                <div className={"rightBox"} onClick={this.nextImage.bind(this)} style={{display: (this.state.currentFullscreen < this.state.pics.length-1 ? "block":"none")}}/>
                <div className={"closeButton"} onClick={this.closeFullscreen.bind(this)}>X</div>
            </div> : "");
        const width = 4 * 100;
        return (
            <div>
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className="samtidsbild_header">Samtidsbild</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <div className="albumpage page">
                        <div className="album">
                            {pictures.length > 0 ? pictures : <div style={{"padding": "20px"}}>Tryck på + knappen i botten på skärmen för att ta din första bild</div>}
                        </div>
                        <div className="footerlogo"><svg viewBox={"0 0 435.5 100"} className="icon" dangerouslySetInnerHTML={{__html: '<use xlink:href="#slmlogo"></use>'}}/></div>
                    </div>
                </IonContent>
                <BottomMenu/>
            </IonPage>
            {fullscreenImage}
        </div>
        );
    }
}
export default withRouter(AlbumPage)
