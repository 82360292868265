// src/components/AboutPage.js
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import BottomMenu from '../components/BottomMenu';
import Termsofuse from '../components/Termsofuse';
import Icon from '../components/Icon';
import {IonContent, IonFooter, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import './AboutPage.scss'

declare const _paq:any;
class AboutPage extends React.Component<any, any> {
    public readonly state: any = {
        openTOU: false
    }

    constructor(props: any, context: any) {
        super(props, context);

        //this.state = {openTOU:false}
    }
    componentDidMount = () => {
        console.log("ABout did Mount");
        _paq.push(["setCustomUrl", "https://samtidsbild.se/about"]);
        _paq.push(["trackPageView", "About"]);
    }

    openTOU = () => {
        this.setState({openTOU:!this.state.openTOU});
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className="samtidsbild_header">Samtidsbild</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    {this.state.openTOU ? <Termsofuse openTOU={this.openTOU}/> :
                        <div className="page aboutPage">
                            <h3>Att tänka på</h3>
                            <ul>
                                <li>Stockholms län är stort, från Singö i Norrtälje till Landsort i
                                    södra Nynäshamn. Vi vill gärna ha bilder från länets alla 26
                                    kommuner.
                                </li>
                                <li>Ladda gärna upp originalfilen, fotografierna kan vara upp till 30MB</li>
                                <li>Filformaten kan vara jpg eller tiff.</li>
                                <li>Märk inte fotografierna med vattenstämplar och skriv inte
                                    heller några texter på fotografiet.
                                </li>
                                <li>Glöm inte att läsa igenom användarvillkoren för Samtidsbild
                                    noga, så att du vet vad som gäller.
                                </li>
                                <li>Samtidsbild är inte en lagringsplats för alla dina fotografier.</li>
                                <li>Gör noggranna urval innan du laddar upp bilder.</li>
                                <li>Försök tänk på vilka slags bilder som skulle kunna intressera
                                    framtida generationer.
                                </li>
                                <li>Titeln ska vara kort.</li>
                                <li>Berätta om bilden i bildtexten med en fyllig och beskrivande
                                    text.
                                </li>
                                <li>Tagga bilden, d v s beskriv bilden med några enstaka ord.
                                    Med hjälp av dessa ord kommer man att kunna söka efter
                                </li>
                                <li>Visa på kartan var bilden är tagen genom att fylla i det aktuella
                                    ortnamnet. Då dyker kartan upp och du kan justera platsen
                                </li>
                            </ul>
                            <p>Sök bland alla samtidsbilder på <a target="_blank" rel="noreferrer"
                                                                   href="https://stockholmslansmuseum.se/samlingar/samtidsbild/">stockholmslansmuseum.se/samlingar/samtidsbild/</a>
                            </p>
                            <button className="registerOK" onClick={this.openTOU}>Användarvillkor</button>
                            <div className="footerlogo"><svg viewBox={"0 0 435.5 100"} className="icon" dangerouslySetInnerHTML={{__html: '<use xlink:href="#slmlogo"></use>'}}/></div>
                            <br/><br/>
                        </div>
                    }
                </IonContent>
                <BottomMenu/>
            </IonPage>
        );
    }
}

export default withRouter(AboutPage)
