// src/components/UploadPage.js
import React, {useRef} from 'react';
import {withRouter} from 'react-router-dom';
import $ from 'jquery';
//import EXIF from '../library/exif';
import EXIF from 'exif-js'
import Map from '../components/Map';
import Auth from '../library/Auth';
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import {Geolocation} from '@capacitor/geolocation';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import "./UploadPage.scss"

interface gpsPos {
    degree: number,
    minute: number,
    second: number,
    direction: string
}

declare const _paq: any;
declare const document: any;
class UploadPage extends React.Component<any, any> {
    public readonly state: any = {
        images:[],
        selected: -1,
        file: null,
        blob: null,
        newfile: null,
        EXIF: null,
        imagething: null,
        page: 0,
        iamauthor: false,
        form: {
            title: "",
            description: "",
            timestamp: "",
            tags: [
                ""
            ],
            gps: {
                "lat":0,
                "lng":0
            },
            removedMarker: false,
            category: "",
            place: ""
        },
        check: {
            author: true,
            title: true,
            description: true,
            timestamp: true,
            category: true
        },
        loading: false,
        uploadComplete: false,
        moreInfo: false,
        licens: 3,
        error: false,
        licenstext: [
            "Creative Commons Erkännande-Ickekommersiell-IngaBearbetningar 2.5 Sverige",
            "Creative Commons Erkännande-IngaBearbetningar 2.5 Sverige",
            "Creative Commons Erkännande-IckeKommersiell-DelaLika 2.5 Sverige",
            "Creative Commons Erkännande-IckeKommersiell 2.5 Sverige",
            "Creative Commons Erkännande-IngaBearbetningar 2.5 Sverige",
            "Creative Commons Erkännande-DelaLika 2.5 Sverige",
            "Creative Commons Erkännande 2.5 Sverige"
        ],
        categories: {
            "Vardag": "Vardag",
            "Tidstypiskt": "Tidstypiskt",
            "Kommunikation": "Kommunikation",
            "Livsstil": "Livsstil",
            "Förändring": "Förändring",
            "Min plats": "Min_plats"
        },
        InputFocused: false
    };

    images: []
    count: number
    searchbox: any

    constructor(props: any) {
        super(props);
        //const fc  = new FileContainer();
        //this.state.file = fc.getFile();
        console.log("UploadPage Props: ", props.location.state);
        if (props.location.state) {
            this.state.file = props.location.state.file;
            this.state.blob = this.b64toBlob(this.state.file.data);
        }
        /*if(this.state.file && this.state.file.blob) {
            this.state.blob = this.state.file.blob;
        }*/
        //console.log("getFile in UploadPage constructor: ", this.state.file);

        this.images = [];
        this.count = 0;
        this.searchbox = React.createRef();
    }
    componentDidMount() {
        /*if(typeof cordova !== 'undefined') {
            this.listDir(cordova.file.externalRootDirectory, function(home) {
                console.log("Done");
                //console.log(home.images);
                home.setState({images:home.images, file:null});
            });
        }*/


        //fc.setFile(e.target.files[0]);
        //console.log(fc.getFile());

        const url = "https://samtidsapp.onspotstory.com/js/categories.json";
        console.log("Downloadin: ", url);
        $.ajax({
            url: url,
            type: "GET",
            crossDomain: true,
            contentType: false,
            cache: false,
            processData:false,
            success: (data: any) => {
                //console.log("Data:",data);
                this.setState({categories:data});
            },
            error: (xhr, status, err) => {
                console.error(url, status, err.toString());
            }
        });

        //console.log(window.location.pathname);
        //console.log("Searchbox: ",this.refs.searchbox);
        //console.log("File: ", this.state.file);
        if(window.location.pathname === "/upload/album") {
            _paq.push(["setCustomUrl", "https://samtidsbild.se/upload/album"]);
            _paq.push(["trackPageView", "Upload Album"]);
            console.log("Upload/Album, load fileref");
            //ReactDOM.findDOMNode(this.refs.fileupload).click();
        }
        else if(window.location.pathname === "/upload/camera") {
            _paq.push(["setCustomUrl", "https://samtidsbild.se/upload/camera"]);
            _paq.push(["trackPageView", "Upload Camera"]);
            this.takePicture();
        }
        console.log("Blob? ", this.state.blob);
        console.log("File?", this.state.file);
        const read = this.state.blob || this.state.file;
        if(read != null) {
            console.log("We got a State-Blob");
            const form = this.state.form;
            form.timestamp = this.getTimestamp();

            this.setState({form:form}, () => {
                console.log("FILE:",read);
                /*var reader = new FileReader();
                reader.onload = function (e) {
                    home.setState({preview: e.target.result}, null);

                    var image = new Image();
                    image.addEventListener("load", function () {
                        //console.log(image.width, image.height);
                        let form = home.state.form;
                        if(home.state.file.lastModifiedDate) {
                            form.timestamp = new Date(home.state.file.lastModified).toISOString();
                        }
                        home.setState({imagething:{width: image.width, height: image.height, offsetWidth: image.offsetWidth, offsetHeight: image.offsetHeight},form:form});
                        image = null;
                    });
                    image.src = e.target.result;
                };
                reader.readAsDataURL(this.state.file);*/
                this.readFile2(read);
                //home.nextPage();
                //up.openGuide();;

                this.readExifData(read);

            });
        }
    }

    readExifData = (read: any) => {
        return new Promise((resolve: any, fail: any) => {
            return EXIF.getData(read, (data: any) => {
                console.log("EXIF Data? ", data);
                const allMetaData = EXIF.getAllTags(read);
                allMetaData['MakerNote'] = null;
                console.log("AllMetaData: ", allMetaData);
                const form = this.state.form;
                if(allMetaData.DateTimeOriginal != null)
                {
                    form.timestamp = allMetaData.DateTimeOriginal;
                }

                const pos = {lat: 0, lng: 0};
                if(allMetaData.GPSLatitude) {
                    if(allMetaData.GPSLatitude[2] != 0) {
                        pos.lat = this.dmsToLonLat({degree: allMetaData.GPSLatitude[0], minute: allMetaData.GPSLatitude[1], second: allMetaData.GPSLatitude[2], direction: allMetaData.GPSLatitudeRef})
                    } else {
                        pos.lat = allMetaData.GPSLatitude[0].valueOf();
                    }
                }
                if(allMetaData.GPSLongitude) {
                    if(allMetaData.GPSLongitude[2] != 0) {
                        pos.lng = this.dmsToLonLat({degree: allMetaData.GPSLongitude[0], minute: allMetaData.GPSLongitude[1], second: allMetaData.GPSLongitude[2], direction: allMetaData.GPSLongitudeRef})
                    } else {
                        pos.lng = allMetaData.GPSLongitude[0].valueOf();
                    }
                }
                console.log("Pos: ", pos);
                if (pos.lat > 0 && pos.lng > 0) {
                    form.gps = pos;
                } else {
                    console.error("GPS pos set to 0/0, don't use");
                }
                //console.log(allMetaData);
                this.setState({EXIF: allMetaData, form:form}, resolve);
                //var allMetaDataSpan = document.getElementById("allMetaDataSpan");
                //allMetaDataSpan.innerHTML = JSON.stringify(allMetaData, null, "\t");*/
            });
        });
    }

    b64toBlob = (b64Data: any, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    dmsToLonLat = (o: gpsPos) => {
        let n = NaN;
        if (o) {
            let t = Number(o.degree);
            const d = "undefined" != typeof o.minute ? Number(o.minute) / 60 : 0,
                  l = "undefined" != typeof o.second ? Number(o.second) / 3600 : 0,
                  r = o.direction || null;
            null !== r && /[SW]/i.test(r) && (t = -1 * Math.abs(t)), n = 0 > t ? t - d - l : t + d + l
        }
        return n
    }

    setTitle = (e: any) => {
        const form = this.state.form;
        form.title = e.target.value;
        this.setState({form:form},this.evalTitle);
    }
    setDescription = (e: any) => {
        const form = this.state.form;
        form.description = e.target.value;
        this.setState({form:form},this.evalDescription.bind(this));
    }
    setTimestamp = (e: any) => {
        const form = this.state.form;
        form.timestamp = e.target.value;
        this.setState({form:form});
    }
    setTags = (e: any) => {
        const form = this.state.form;
        form.tags = e.target.value.replace(/ /g, "").split(",");
        //console.log(form.tags);
        this.setState({form:form});
    }
    setPlace = (e: any) => {
        const form = this.state.form;
        form.place = e.target.value;
        this.setState({form:form});
    }
    setIamAuthor = () => {
        this.setState({iamauthor: !this.state.iamauthor},this.evalIamAuthor);
    }
    setCategory = (e: any) => {
        const form = this.state.form;
        form.category = e.target.value;
        this.setState({form:form},this.evalCategory);
    }
    setGPS = (gps: any) => {
        const form = this.state.form;
        form.gps = gps;
        this.setState({form:form},() => { console.log(this.state.form.gps) });
    }
    getCurrentPos = (e: any) => {
        if (e) {
            e.preventDefault();
        }

        /* Get GPS POS */
        const get = () => {
            Geolocation.getCurrentPosition().then((pos) => {
                console.log("GPS Pos:",pos.coords.latitude, pos.coords.longitude);
                const form = this.state.form;
                form.gps = {"lat":pos.coords.latitude,"lng":pos.coords.longitude};
                this.setState({form:form});
            },() => {
                console.error("Could not get location");
            });
        }
        //navigator.geolocation.getCurrentPosition((pos) => {
        Geolocation.checkPermissions().then((status) => {
            console.log("Location Permission Status: ", status);
            if (status.location === "prompt") {
                Geolocation.requestPermissions({permissions: ['location']}).then((status) => {
                    console.log("Request Permission Status: ", status);
                    get();
                })
            } else {
                get();
            }
        })
    }
    /*setFile = (e: any) => {
        //console.log(this,e);
        console.log("Setting File");

        if (e.target.files && e.target.files[0]) {
            const form = this.state.form;
            form.timestamp = this.getTimestamp();

            this.setState({file: e.target.files[0],form:form}, () => {
                console.log("FILE: ", this.state.file);
                const reader = new FileReader();
                reader.onload = (e:ProgressEvent<FileReader>) => {
                    console.log("Reader OnLoad");
                    if(e && e.target && e.target.result) {
                        this.setState({preview: e.target.result});
                    }

                    const image = new Image();
                    image.addEventListener("load", () => {
                        console.log("ImageLoaded",image.width, image.height);
                        this.setState({imagething:{width: image.width, height: image.height, offsetWidth: image.offsetWidth, offsetHeight: image.offsetHeight}});
                        //image = null;
                    });
                    if (e && e.target && typeof e.target.result === "string") {
                        image.src = e.target.result;
                    }
                };
                reader.readAsDataURL(this.state.file);
                this.nextPage();
                //up.openGuide();;
                console.log("EXIF GET DATA");
                //TODO ADD EXIF LIB
                EXIF.getData(this.state.file, () => {
                    const allMetaData = EXIF.getAllTags(this);
                    console.log("All EXIF data: ",allMetaData);
                    const form = this.state.form;
                    if(allMetaData.DateTimeOriginal != null)
                    {
                        form.timestamp = allMetaData.DateTimeOriginal;
                    }
                    if(allMetaData.gpsposition != null)
                    {
                        //todo parse the GPS thing
                        //todo this.setGPS(parsed Cordinates);
                    }
                    //console.log(allMetaData);
                    this.setState({EXIF: allMetaData, form:form});
                    //var allMetaDataSpan = document.getElementById("allMetaDataSpan");
                    //allMetaDataSpan.innerHTML = JSON.stringify(allMetaData, null, "\t");
                });

            });
        }
    }*/
    setDescriptionInitial = () => {
        // this.refs.description.value = this.state.form.description;
    }
    setLicens = (e: any) => {
        this.setState({licens: parseInt(e.target.value)});
    }

    scrollToTop = () => {
        // TODO Make this work
        return true;
        // const contentRef = useRef<HTMLIonContentElement | null>(null);
        // contentRef.current && contentRef.current.scrollToTop();
    };
    nextPage = () => {
        console.log("Next page", this.state.page);
        if(this.state.page < 4)
        {
            let newpage = this.state.page;
            if(this.state.page == 0)
            {
                const IamAuthor = this.evalIamAuthor();
                const title = this.evalTitle();
                const desc = this.evalDescription();
                const category = this.evalCategory();
                const timestamp = this.evalTimestamp();
                if(IamAuthor && title && desc && category && timestamp)
                {
                    console.log("All is good");
                    newpage = this.state.page + 1;
                }
            }
            else
            {
                newpage = this.state.page + 1;
                // TODO Make Scroll To Top Work
                // this.scrollToTop();
            }
            let loading = this.state.loading;
            if(this.state.page == -1)
            {
                loading = false;
            }
            this.setState({page:newpage,loading:loading}, () => {
                /*if(this.state.page = 1)
                {
                    this.refs.gm1.redraw();
                }
                if(this.state.page = 2)
                {
                    this.refs.gm2.redraw();
                }*/
            })
        }
    }
    previousPage = () => {
        if(this.state.page > 0)
        {
            this.setState({page:(this.state.page - 1),error:false})
        }
    }
    cancel = () => {
        const history = this.props.history;
        //history.goBack();
        history.replace("/album");
    }
    listDir = (path: string, callback: any) => {
        const blacklist = ["Android"];
        console.log("Reading: " + path);
        this.count++;

        /* //TODO THIS LOOKS IMPORTANT
        window.resolveLocalFileSystemURL(path, (fileSystem) => {
                const reader = fileSystem.createReader();
                reader.readEntries(
                    (entries) => {
                        //console.log("Found: " + entries.length + " entries");
                        //console.log(entries);
                        //return entries;
                        for(let i = 0; i < entries.length; i++)
                        {
                            const entry = entries[i];
                            if(entry.isDirectory && blacklist.indexOf(entry.name) < 0)
                            {
                                this.listDir(path+"/"+entry.name,callback);
                            }
                            else if(entry.isFile)
                            {
                                var parts = entry.name.split(".");
                                if(parts[parts.length-1] == "jpg")
                                {
                                    this.images.push(entry.nativeURL);
                                    //console.log(entry.nativeURL);
                                }
                            }
                        }
                        this.count--;
                        //console.log(home.count);
                        if(this.count === 0 && callback)
                        {

                            callback(this);
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            }, (err) => {
                console.log(err);
            }
        );*/
    }
    getTimestamp() {
        const today = new Date();
        console.log(today);
        const dd = today.getDate();
        const mm = today.getMonth()+1;
        const yyyy = today.getFullYear();
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        const timestamp = yyyy+":"+(mm<10?'0'+mm:mm)+":"+(dd<10?'0'+dd:dd)+" "+(hours<10?'0'+hours:hours)+":"+(minutes<10?'0'+minutes:minutes)+":"+(seconds<10?'0'+seconds:seconds);
        console.log(timestamp);
        return timestamp;
    }
    upload = (e: any) => {
        e.preventDefault();
        if(this.evalTitle() && this.evalDescription() && this.evalCategory() && this.evalIamAuthor() && this.evalTimestamp())
        {
            if(this.state.blob != null)
            {
                console.log("Old grummy photo");
                this.nextPage();
                this.upload1();
            }
            else if(this.state.newfile != null)
            {
                console.log("Freshly taken photo");
                this.nextPage();
                this.upload2();
            }
            else
            {
                console.log("No files to upload...");
            }
        }
        else
        {
            console.log("Data missing");
            this.setState({error:true});
        }
    }
    upload1 = () => {
        /*
         form: {
         title: "",
         description: "",
         timestamp: "",
         tags: [
         ""
         ],
         gps: {
         lat: 59.30894035954075,
         lng: 18.091218173503876
         },
         removedMarker: false,
         category: "",
         place: ""
         },
         */


        //var img = this.state.images[this.state.selected];
        //console.log("Picture to be uploaded: " + img);
        //console.log(this,e);
        console.log(this.state.file);
        this.setState({loading:true});

        const data = new FormData();
        data.append('file', this.state.blob);
        if(!this.state.form.removedMarker){
            data.append('gps', (this.state.form.gps.lat+","+this.state.form.gps.lng));
        }
        let tags = "";
        for(let i = 0; i < this.state.form.tags.length; i++)
        {
            let tag = this.state.form.tags[i];
            //console.log("Firstchar: ",tag.charAt(0));
            if(tag.charAt(0) == "#")
            {
                tag = tag.substring(1);
            }
            tags += tag;
            if(i < this.state.form.tags.length-1)
            {
                tags += ",";
            }
        }
        console.log(tags);
        data.append('title', this.state.form.title);
        data.append('description', this.state.form.description);
        data.append('place_domestic', this.state.form.place);
        data.append('category', this.state.form.category);
        data.append('timestamp', this.state.form.timestamp);
        data.append('licence', this.state.licens);
        data.append('category', this.state.form.category);
        data.append('originalName', this.state.file.name)
        data.append('tags', tags);

        //console.log(data);

        const authtoken = localStorage.getItem('authToken');
        const url = "https://api.samtidsbild.museum.link/upload?authToken="+authtoken;
        console.log(url, {
            "title":this.state.form.title,
            "description": this.state.form.description,
            "place_domestic":this.state.form.place,
            "category": this.state.form.category,
            "timestamp": this.state.form.timestamp,
            "licence": this.state.licens,
            "tags": tags
        });
        Auth.checkValid(() => {
            const history = this.props.history;
            $.ajax({
                url: url,
                type: "POST",
                crossDomain: true,
                contentType: false,
                cache: false,
                data: data,
                processData:false,
                success: (result: any) => {
                    //console.log("Uploaded!",data);
                    this.setState({loading:false});
                    //home.setState({uploadComplete:true});
                    console.log('Upload success: >',result, "<");
                    console.log(result.bytesSent, 'bytes sent');
                    console.log("PUshing History to upload/Gratz, going backwards");
                    history.replace('/upload/gratz');
                },
                error: (xhr, status, err) => {
                    console.error(url, status, err.toString());
                }
            });
        });

    }
    upload2 = () => {
        console.log(this.state.newfile);
        this.setState({loading:true});

        const authtoken = localStorage.getItem('authToken');
        const url = "https://api.samtidsbild.museum.link/upload?authToken="+authtoken;
        console.log(url);

        /*var ft = new FileTransfer(),
            path = this.state.newfile.fullPath,
            name = this.state.newfile.name;
        ft.onprogress = function(progressEvent) {
            //console.log(progressEvent);
            if (progressEvent.lengthComputable) {
                //loadingStatus.setPercentage(progressEvent.loaded / progressEvent.total);
            } else {
                //loadingStatus.increment();
            }
        };*/

        let tags = "";
        for(let i = 0; i < this.state.form.tags.length; i++)
        {
            let tag = this.state.form.tags[i];
            //console.log("Firstchar: ",tag.charAt(0));
            if(tag.charAt(0) == "#")
            {
                tag = tag.substring(1);
            }
            tags += tag;
            if(i < this.state.form.tags.length-1)
            {
                tags += ",";
            }
        }
        const params = {
            "title": this.state.form.title,
            "description": this.state.form.description,
            "place_domestic": this.state.form.place,
            "category": this.state.form.category,
            "timestamp": this.state.form.timestamp,
            "licence": this.state.licens,
            "tags": tags,
            "gps": ""
        };
        if(!this.state.form.removedMarker){
            //data.append('gps', (this.state.form.gps.lat+","+this.state.form.gps.lng));
            params['gps'] = (this.state.form.gps.lat+","+this.state.form.gps.lng);
        }
        console.log("Params:",params);

        //console.log(ft);
        /*ft.upload(path,
            url,
            (result: any) => {
                this.setState({loading:false});
                console.log('Upload success: >',result,"<");
                console.log(result.bytesSent, 'bytes sent');
                const history = this.props.history;
                history.push('/upload/gratz');
            },
            (e: any) => {
                console.log('Error uploading file ' + path + ': ', e);
            },
            {
                fileName: name,
                params: params
            });*/
        /*
         data.append('title', this.state.form.title);
         data.append('description', this.state.form.description);
         data.append('place_domestic', this.state.form.place);
         data.append('category', this.state.form.category);
         data.append('timestamp', this.state.form.timestamp);
         data.append('licence', this.state.licens);
         data.append('category', this.state.form.category);
         data.append('tags', tags);
         */
    }
    takePicture = () => {
        console.log("TAKE PICTURE");

        Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            saveToGallery: true,
            source: CameraSource.Camera,
            resultType: CameraResultType.Base64
        }).then((image) => {
            console.log("Taken Image: ", image);
            if (!image) {
                this.cancel();
            } else {
                this.setState({
                    file: image,
                    blob: this.b64toBlob(image.base64String)
                }, () => {
                    this.readFile2(this.state.blob).then(()=> {
                        console.log("Taken Picture, read Exif data");
                        this.readExifData(this.state.blob).then((e: any) => {
                            if(this.state.form.gps.lat === 0 && this.state.form.gps.lng === 0) {
                                console.log("ReadExifData did not set a position, so read it from GPS instead");
                                this.getCurrentPos(e);
                            }
                            if(!this.state.form.timestamp) {
                                const form = this.state.form;
                                form.timestamp = new Date().toISOString();
                                this.setState({form});
                            }
                        });
                    })
                })
            }
        }).catch((error) => {
            console.error(error);
            this.cancel();
        })
        /*navigator.device.capture.captureImage((mediaFiles: any) => {
            let i, path, len;
            for (i = 0, len = mediaFiles.length; i < len; i += 1) {
                var file = mediaFiles[i];
                console.log("FILE:",file);

                const form = this.state.form;
                form.timestamp = this.getTimestamp();

                this.setState({preview: file.localURL,newfile: file,form:form, file:file}, () => {
                    console.log("State set with preview");


                    /*home.readFile2();
                    return;* /
                    this.readFile(this.state.newfile, (blob: any) => {
                        //console.log(blob);
                        /*EXIF.getData(blob, () => {
                            const allMetaData = EXIF.getAllTags(blob);
                            console.log("ALL EXIF DATA:",allMetaData);
                            const form = this.state.form;
                            //var rotate = home.state.rotate;
                            if(allMetaData.DateTimeOriginal != null)
                            {
                                form.timestamp = allMetaData.DateTimeOriginal;
                            }
                            if(allMetaData.gpsposition != null)
                            {
                                //todo parse the GPS thing
                                //todo this.setGPS(parsed Cordinates);
                            }
                            /*if(allMetaData.Orientation != null)
                            {
                                console.log(allMetaData.Orientation);
                                if(allMetaData.Orientation == 6)
                                {
                                    rotate = 90;
                                }
                            }* /

                            this.setState({EXIF: allMetaData, form:form});
                            //var allMetaDataSpan = document.getElementById("allMetaDataSpan");
                            //allMetaDataSpan.innerHTML = JSON.stringify(allMetaData, null, "\t");
                        });*/

                        /*var image = new Image();
                        image.addEventListener("load", function () {
                            //console.log(image.width, image.height);
                            home.setState({imagething:{width: image.width, height: image.height, offsetWidth: image.offsetWidth, offsetHeight: image.offsetHeight}});
                            image = null;
                        });
                        image.src = window.URL.createObjectURL(blob);* /
                    });
                    //home.nextPage();
                });
            }
        }, (error) => {
            error.log(error);
        }, {limit:1});*/
    }
    openMoreInfo = () => {
        this.setState({moreInfo:!this.state.moreInfo});
    }
    removeMarker = () => {
        const form = this.state.form;
        form.removedMarker = !form.removedMarker;
        this.setState({form:form});
    }
    selectImg = (i: number) => {
        console.log("Selected: " + i);
        this.setState({selected: i});
    }
    readFile = (file: any, callback: any) => {
        console.log("Reading: ", file);
        this.count++;
        const path = file.fullPath;

        /* TODO SOLVE THIS
        window.resolveLocalFileSystemURL(path,
            (fileEntry: any) => {
                console.log("FileEntry: ", fileEntry);
                fileEntry.file((file: any) => {
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        console.log("Read File made a Blob");
                        const blob = new Blob([new Uint8Array(this.result)], { type: "image/png" });
                        callback(blob);
                    };

                    reader.readAsArrayBuffer(file);

                }, (error: any) => {error.log("Error",error)});
            }, (err: any) => {
                console.log(err);
            }
        );*/
    }
    readFile2 = (file: any) => {
        console.log("readFile2:",file);
        const reader = new FileReader();
        return new Promise((resolve: any, fail: any) => {
            reader.onload = (e: ProgressEvent<FileReader>) => {
                console.log("Reader OnLoad: ", e);
                //console.log("Reader Result: ", reader.result);
                this.setState({preview: reader.result});

                const image = new Image();
                //console.log("Fresh Image: ", image);
                image.addEventListener("load",  () =>{
                    console.log(image.width, image.height);
                    const form = this.state.form;
                    if(this.state.blob.lastModifiedDate) {
                        form.timestamp = new Date(this.state.blob.lastModified).toISOString();
                    }
                    this.setState({imagething:{width: image.width, height: image.height, offsetWidth: image.offsetWidth, offsetHeight: image.offsetHeight},form:form}, resolve);
                });
                if (typeof reader.result === "string") {
                    image.src = reader.result;
                }
            };
            reader.readAsDataURL(file);
        });

    }
    calculateRotate = () => {
        const rotatestyle: any = {};
        let landscape = false;
        let current_orientation = 0;
        let correct_orientation = 0;
        //var debug = {};
        if(this.state.EXIF != null)
        {
            if( this.state.EXIF.Orientation != null)
            {
                const orientation = this.state.EXIF.Orientation;
                correct_orientation = orientation;
                console.log("Orientation: ",orientation);
                //debug.orientation = orientation;
                if(orientation == 1 || orientation == 3)
                {
                    landscape = true;
                    console.log("EXIF Orientation Liggande bild");
                }
                else if(orientation == 8 || orientation == 6)
                {
                    landscape = false;
                    console.log("EXIF Orientation Stående bild")
                }
            }

            if(this.state.EXIF.PixelXDimension > this.state.EXIF.PixelYDimension)
            {
                landscape = true;
                //correct_orientation = 1;
                console.log("EXIF Res Liggande bild")
            }
            else if(this.state.EXIF.PixelXDimension < this.state.EXIF.PixelYDimension)
            {
                landscape = false;
                //correct_orientation = 6;
                console.log("EXIF Res Stående bild")
            }
        }

        if(this.state.imagething != null)
        {
            console.log(this.state.imagething);
            //debug.imagething = this.state.imagething;
            if(this.state.imagething.width > this.state.imagething.height)
            {
                landscape = true;
                console.log("Res Liggandes");
                current_orientation = 1;
            }
            else if(this.state.imagething.width < this.state.imagething.height)
            {
                landscape = false;
                console.log("Res Ståendes");
                current_orientation = 6;
            }
            if(this.state.imagething.offsetWidth > this.state.imagething.offsetHeight)
            {
                console.log("Offset Res Liggandes");
            }
            else if(this.state.imagething.offsetWidth < this.state.imagething.offsetHeight)
            {
                console.log("Offset Res Ståendes");
            }
        }
        console.log(current_orientation, correct_orientation);
        if(current_orientation != correct_orientation)
        {
            // We need to rotate
            let rotate = 0;
            console.log("We need to rotate");
            const cuo = current_orientation;
            const coo = correct_orientation;
            if(cuo == 1 && coo == 6)
            {
                rotate = 90;
            }
            else if(cuo == 1 && coo == 8)
            {
                rotate = -90;
            }

            console.log("Rotate :" + rotate + "deg");
            rotatestyle.transform = "translate(-50%) rotate("+rotate+"deg)";
            rotatestyle.width = "35vh";
            rotatestyle.height = "35vh";
        }
        return rotatestyle;
    }

    evalTitle = () => {
        let retur = false;
        if(this.state.form.title.length > 0) { retur = true; }
        const check = this.state.check;
        check.title = retur;
        this.setState({check:check});
        console.log("Eval Title: ", retur);
        return retur;
    }
    evalDescription = () => {
        let retur = false;
        if(this.state.form.description.length > 0) { retur = true; }
        const check = this.state.check;
        check.description = retur;
        this.setState({check:check});
        console.log("Eval Description: ", retur);
        return retur;
    }
    evalIamAuthor = () => {
        const check = this.state.check;
        check.author = this.state.iamauthor;
        console.log("Author:",check.author);
        this.setState({check:check});
        console.log("Eval IamAuthor: ", this.state.iamauthor);
        return this.state.iamauthor;
    }
    evalCategory() {
        let retur = false;
        //console.log("Cat",this.state.form.category.length);
        if(this.state.form.category.length > 0) {
            retur = true;
        }
        const check = this.state.check;
        check.category = retur;
        this.setState({check:check});
        console.log("Eval Category: ", retur);
        return retur;
    }
    evalTimestamp() {
        //console.log("Tim",this.state.form.timestamp.length);
        let retur = false;
        if(this.state.form.timestamp.length > 0) {
            retur = true;
        }
        const check = this.state.check;
        check.timestamp = retur;
        this.setState({check:check});
        console.log("Eval Timestamp: ", retur);
        return retur;
    }
    evalPlacename() {
        //console.log("Pla",this.state.form.place.length);
        return (this.state.form.place.length > 0)
    }

    /*
     $payload = array(
     "intrinsic_fields" => array(
     "type_id"=> "76",
     "idno"=> $user->email
     ),
     "preferred_labels" => array(
     array(
     "locale"=> "sv_SE",
     "displayname"=> ($user->fname.$user->lname)
     )
     )
     );
     */

    focusInput() {
        /* When any input in focus add padding to bottom on iOS devices */
        console.log("FOCUS INPUT!", !this.state.InputFocused);
        this.setState({InputFocused: !this.state.InputFocused});
    }

    render() {
        //var rotatestyle = this.calculateRotate();

        const rotatestyle = {};
        /*
         rotatestyle.transform = "translate(-50%) rotate("+this.state.rotate+"deg)";
         rotatestyle.maxWidth = "35vh";
         */
        let licens = null;
        switch(this.state.licens) {
            case 0:
                licens = (
                    <div>
                        <p>Licensen <a target="_blank" rel="noreferrer" href="http://creativecommons.org/licenses/by/2.5/se/">Creative Commons erkännande</a> innebär att du, som upphovsman, tillåter andra att använda, sprida, göra om, modifiera och bygga vidare på ditt verk, även i kommersiella sammanhang. De som använder dina Creative Commons licensierade verk ska uppge att det är du som är upphovsman, när de använder, bearbetar eller sprider ditt verk.</p>
                    </div>);
                break;
            case 1:
                licens = (
                    <div>
                        <p>Licensen <a target="_blank" rel="noreferrer" href="http://creativecommons.org/licenses/by-sa/2.5/se/">Creative Commons erkännande, dela lika</a> innebär att du tillåter andra att använda, sprida, göra om, modifiera och bygga vidare, skapa nya verk på ditt verk, så länge de anger att du är upphovsman. Om det skapas nya verk utifrån ditt verk så ska dessa verk spridas och licensieras med samma licens, alltså nya verk ska spridas under samma villkor.</p>
                        <p>Alla nya verk som skapas utifrån ett verk som har den här licensen ska ha samma licens, som ursprungsverket.</p>
                    </div>);
                break;
            case 2:
                licens = (
                    <div>
                        <p>Licensen <a target="_blank" rel="noreferrer" href="http://creativecommons.org/licenses/by-nd/2.5/se/">Creative Commons erkännande, inga bearbetningar</a> innebär att du som upphovsman tillåter spridning, kommersiell och ickekommersiell, men du tillåter inte att verket bearbetas. De som sprider ditt verk under denna licens ska sprida ditt verk oförändrat, och du tillåter alltså inte att verket bearbetas.I samband med användning eller spridning av verket ska upphovsmannen också anges.</p>
                    </div>);
                break;
            case 3:
                licens = (
                    <div>
                        <p>Licensen <a target="_blank" rel="noreferrer" href="http://creativecommons.org/licenses/by-nc/2.5/se/">Creative Commons erkännande, icke-kommersiell</a> innebär att du tillåter andra att använda, sprida, göra om, modifiera och bygga vidare på ditt verk, men inte att verket används i kommersiella sammanhang. I samband med att verket används så ska också upphovsmannen anges.</p>
                    </div>);
                break;
            case 4:
                licens = (
                    <div>
                        <p>Licensen <a target="_blank" rel="noreferrer" href="http://creativecommons.org/licenses/by-nc-sa/2.5/se/">Creative Commons erkännande, icke kommersiell, dela lika</a> innebär att du tillåter andra att använda, sprida, göra om, modifiera och bygga vidare, och skapa nya verk utifrån ditt. Så länge de erkänner dig som upphovsman och att de verk som skapas utifrån ditt verk ska licensieras  under samma villkor. De nya verk som skapas utifrån ditt verk kommer att ha samma licens.</p>
                    </div>);
                break;
            case 5:
                licens = (
                    <div>
                        <p>Licensen <a target="_blank" rel="noreferrer" href="http://creativecommons.org/licenses/by-nc-nd/2.5/se/">Creative Commons erkännande, icke kommersiell, inga bearbetningar</a> innebär att du tillåter spridning men bara i icke kommersiella sammanhang. Verk som ligger under den här licensen får inte bearbetas och den som använder verket måste du erkänna dig som upphovsman.</p>
                    </div>);
                break;
        }

        const categories_options = [
            <option key="0" value="">Välj</option>
        ];
        for (const k in this.state.categories) {
            //if(this.state.categories.hasOwnProperty(k)) {
                categories_options.push( <option key={k} value={this.state.categories[k]}>{k}</option>)
            //}
        }

        let error_bgcolor = "#fb4921";
        error_bgcolor = "red";
        const error_color = "#fff";
        //const device = undefined;
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className="samtidsbild_header">Samtidsbild</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                {/*className={ + (typeof device !== "undefined" && device.platform === "iOS" ? "InputFocused":"NoFocus")}*/}
                {/*<input type="file" name="file" ref="fileupload" accept="image/*" id="file" style={{"display": "block"}} onChange={this.setFile.bind(this)} />*/}
                    <div className={"uploadPage page"}>
                        <div className="content" style={{"display": (this.state.page == -1 ? "block" : "none")}}>
                            <div className="avbryt" onClick={this.cancel}>Avbryt</div>
                        </div>
                        <div className="wizard">
                            <form>
                                <div className="subpage" style={{"display": (this.state.page == 0 ? "block" : "none")}}>
                                    <h3 className="uploadProgress">Information om bilden - steg 1 av 3</h3>
                                    <div className="previewImageDiv"><img className="previewImage" src={this.state.preview} style={rotatestyle}/></div>
                                    <div className="input myPic">
                                        <label className={(this.state.check.author ? "full":"empty")}>Jag har fotograferat bilden <input type="checkbox" id="myPic" onChange={this.setIamAuthor} value={this.state.iamauthor}/></label>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="titel">Titel</label>
                                        <input type="text" /*ref="titel"*/ id="title" placeholder="ex: Första dagen på semestern" onChange={this.setTitle} className={(this.state.check.title ? "full":"empty")}/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="beskrivning">Berätta om bilden</label>
                                        <br/>
                                        <em className={"ifYouSocialShare"}>Om du delat den på sociala medier, använd gärna samma text.</em>
                                        <textarea
                                            /*ref="description"*/
                                            id="description"
                                            onChange={this.setDescription}
                                            onFocus={this.setDescriptionInitial}
                                            className={(this.state.check.description ? "full":"empty")}
                                            placeholder={"ex. Det finns inget bättre än att ligga i hängmattan på sommaren med katten vid fötterna och en bra bok."}
                                        >
									</textarea>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="tidpunkt">Tidpunkt</label>
                                        <input
                                            type="text"
                                            /*ref="timestamp"*/
                                            id="timestamp"
                                            placeholder="Tidpunkt"
                                            value={this.state.form.timestamp}
                                            onChange={this.setTimestamp}
                                            className={(this.state.check.timestamp ? "full":"empty")}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="category">Kategori</label>
                                        <select
                                            id="category"
                                            onChange={this.setCategory}
                                            value={this.state.form.category}
                                            className={(this.state.check.category ? "full":"empty")}
                                        >
                                            {categories_options}
                                        </select>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="taggar">Tagga bilden (skriv kommatecken mellan orden)</label>
                                        <input
                                            type="text"
                                            /*ref="tags"*/
                                            id="tags"
                                            placeholder="ex. Selfie, love, fika, semester"
                                            onChange={this.setTags}
                                        />
                                    </div>
                                    <div className="previousPage" onClick={this.cancel}>Avbryt</div>
                                    <div className="nextPage" onClick={this.nextPage}>Nästa</div>
                                </div>
                                <div className="subpage" style={{"display": (this.state.page === 1 ? "block" : "none")}}>
                                    <h3 className="uploadProgress" id={"progressPage2"}>Information om bilden - steg 2 av 3</h3>
                                    <div style={{"textAlign": "center", "fontSize": "0.8em"}}>Tryck på kartan för att markera vart fotot togs</div>
                                    <div>{(this.state.page === 1 ? <Map
                                        /* marker={this.state.form.removedMarker} */
                                        removedMarker = {this.state.form.removedMarker}
                                        pos = {this.state.form.gps}
                                        setGPS={this.setGPS}
                                        /* searchbox={this.searchbox} */
                                        id={"map1"}
                                    /> : <></>)}</div>
                                    {this.state.form.gps.lat > 0 && this.state.form.gps.lng > 0 ? <></>:<div>GPS Position saknas</div>}
                                    <button style={{margin: "20px 0 0 0"}} className="findMe" onClick={this.getCurrentPos}>Välj min position</button>
                                    <div className="input" style={{display: "none"}}>
                                        <input type="text" placeholder="Sök på karta" ref={this.searchbox}/>
                                    </div>
                                    <div className="removeMarker input">
                                        <label>Ta bort kartmarkering <input type="checkbox" id="removeMarker" value="removeMarker" checked={this.state.form.removedMarker} onChange={this.removeMarker}/></label>
                                        <br/>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="ytterligare platsanmn">Ange ytterligare platsnamn:</label>
                                        <input type="text" /*ref="moreplaces"*/ id="moreplaces" placeholder="ex. Namn på område, stadsdel, kvarter, hus" onChange={this.setPlace}/>
                                    </div>
                                    <div className="input">
                                        <label style={{margin:"0 0 10px 0"}}>
                                            Välj licens så att andra vet hur de får använda bilden.<br/>
                                            <select name="cc" id="id_cc" onChange={this.setLicens} value={this.state.licens}>
                                                <option value="0">CC BY (Mitt namn visas alltid)</option>
                                                {/*<option value="1">CC BY-SA</option>*/}
                                                {/*<option value="2">CC BY-ND</option>*/}
                                                <option value="3">CC BY-NC (Får ej användas kommersiellt)</option>
                                                {/*<option value="4">CC BY-NC-SA</option>*/}
                                                {/*<option value="5">CC BY-NC-ND</option>*/}
                                            </select>
                                            {/*<div className="licensdescription">{this.state.licenstext[this.state.licens]}</div>*/}
                                            <div className="licensdescription">
                                                {licens}
                                            </div>
                                        </label>
                                    </div>
                                    <div className="previousPage" onClick={this.previousPage}>Föregående</div>
                                    <div className="nextPage" onClick={this.nextPage}>Nästa</div>
                                </div>
                                {(this.state.page === 2 ? <div className="subpage pageThree" id={"progressPage3"}>
                                    <h3 className="uploadProgress">Information om bilden - steg 3 av 3</h3>
                                    <div className="previewImageDiv"><img className="previewImage" src={this.state.preview} style={rotatestyle}/></div>
                                    <div className="summary"> {/* style={{backgroundColor: (this.evalTitle() ? "transparent" : "#f4ab51")}} */}
                                        <div className="IAmAuthor"><label>Jag har fotograferat bilden <input type="checkbox" disabled={true} checked={this.state.iamauthor}/></label></div>
                                        <div><span className="phat">Titel: </span> <br/><span>{this.state.form.title}</span></div>
                                        <div><span className="phat">Beskrivning: </span><br/><span>{this.state.form.description}</span></div>
                                        <div><span className="phat">Tidpunkt: </span> <br/><span>{this.state.form.timestamp}</span></div>
                                        <div><span className="phat">Kategori: </span> <br/><span>{this.state.form.category}</span></div>
                                        {/*<div><span>Taggar:</span> <span style={{whiteSpace: "pre"}}>{JSON.stringify(this.state.form.tags, null, 2)}</span></div>*/}
                                        {this.state.form.tags.length > 0 ? <div><span className="phat">Taggar: <br/>{this.state.form.tags.join(", ")}</span></div> : ""}
                                        <div style={{margin: "0 0 20px 0"}}>{(this.state.page === 2 ? <Map
                                            removedMarker={this.state.form.removedMarker}
                                            pos = {this.state.form.gps}
                                            /* setGPS={this.setGPS} */
                                            /* searchbox={this.refs.searchbox} */
                                            readonly={true}
                                        /> : <></>)}</div>
                                        {!this.state.form.removedMarker && this.state.form.gps.lat > 0 && this.state.form.gps.lng > 0 ? <div className="tinyGPS">GPS Position: <span>Latitud {(this.state.form.gps.lat+"").substring(0,8) +" Longitud "+ (this.state.form.gps.lng+"").substring(0,8)}</span></div> : <div className="tinyGPS">Ingen GPS position sparas</div>}
                                        {/*<div>{(this.state.form.removedMarker ? "Markering borta" : "Markering kvar")}</div>*/}
                                        {this.state.form.place ? <div><span>Platsnamn: {this.state.form.place}</span></div> : ""}
                                        <div style={{color:"#f4ab51", border:"none"}} onClick={this.openMoreInfo.bind(this)}><span>Metadata om bilden</span></div>
                                        <div className="metadata" style={{whiteSpace: "pre", display: (this.state.moreInfo == true ? "block" : "none")}}><span>{JSON.stringify(this.state.EXIF, null, 2)}</span></div>
                                        <div className="whitespace"/>
                                    </div>

                                    <div className="previousPage" onClick={this.previousPage.bind(this)}>Föregående</div>
                                    <div className="upload" onClick={this.upload.bind(this)}>Ladda upp</div>
                                    {this.state.error ? <div className={"errorPopup activated"}>Du måste fylla i de markerade fälten.</div> : <></>}
                                </div> : "" )}
                                <div className="subpage" style={{"display": (this.state.page == 3 ? "block" : "none")}}>
                                    <div className="uploadingText">Laddar upp...</div>
                                </div>
                                <div className="subpage" style={{"display": (this.state.page == 4 ? "block" : "none")}}>
                                    <div className="loadingspinner"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </IonContent>
                <div className="loadingspinner" style={{"display": (this.state.loading ? "block" : "none")}}/>
            </IonPage>
        );
    }
    //<BottomMenu/>
}

export default withRouter(UploadPage)
