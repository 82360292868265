// src/components/BottomMenu.js
import React from 'react';
import { Link, Router } from 'react-router-dom';
import {Library, Loader} from '@googlemaps/js-api-loader';
import {GoogleMap, Marker, StandaloneSearchBox, useJsApiLoader} from '@react-google-maps/api';
import './Map.scss'

const containerStyle = {
    width: '100%',
    height: '380px'
};
const libraries: Library[] = ["places"]

function Map(props: any) {
    console.log("Map - Props: ", props);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAqFtWlhI2llPAO0GIw7NeqJP4JChOBbpY",
        libraries
    })

    const [map, setMap] = React.useState(null)
    //const [pos, setPos] = React.useState(props.pos)

    const onLoad = React.useCallback(function callback(map: any) {
        //const bounds = new window.google.maps.LatLngBounds(props.pos);
        //map.fitBounds(bounds);
        console.log("Searchbox Reference", props.searchbox);
        /*props.searchbox.onChange = (e: any) => {
            console.log("INput changed: ", e);
        }*/
        const sbox = new window.google.maps.places.SearchBox(props.searchbox);
        /*props.searchbox.addListener('change', (e: any) => {
            console.log("Change!", e);
        })*/
        sbox.addListener('places_changed', () => {
            console.log("Places Changed");
            const places = sbox.getPlaces();
            console.log("Places: ", places);
        });
        console.log("Sbox setup: ", sbox);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    const options: any = {
        streetViewControl: false
    }

    if(props.readonly) {
        options.draggable = false;
        options.scrollwheel = true;
        options.panControl = false;
        options.disableDefaultUI = true;
    }
    const onClick = (event: any) => {
        if(event.latLng && props.setGPS) {
            const latLng = { lat: event.latLng.lat(), lng: event.latLng.lng()}
            console.log("MAP CLICKED!!", latLng);
            //setPos(latLng);
            props.setGPS(latLng);
        }
    }

    //console.log("REMOVE MARKER? ", props.removedMarker);
    const pos = (props.pos.lat > 0 && props.pos.lng > 0 ? props.pos : {lat: 59.3294410092617, lng: 18.069483835680323});

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={pos}
            zoom={12}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={options}
            mapContainerClassName={"googleMap"}
            onClick={onClick}
        >
            { /* Child components, such as markers, info windows, etc. */ }
            {!props.removedMarker && props.pos.lat > 0 && props.pos.lng > 0 ? <Marker
                position={props.pos}
            /> : <></>}
        </GoogleMap>
    ) : <></>
}

export default React.memo(Map)
