import React, {FormEvent, FormEventHandler} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { Link, withRouter, useHistory} from 'react-router-dom';
import Termsofuse from '../components/Termsofuse';
import './Welcome.scss';
import Auth from "../library/Auth";
import Icon from "../components/Icon";

declare const _paq: any;
class Welcome extends React.Component<any, any> {

    public readonly state: any = {
        email:localStorage.getItem('email'),
        password: localStorage.getItem('password'),
        openTOU:false,
        wrongLogin: false
    }

    /*constructor(props: any) {
        super(props);

        this.state = {
            email:localStorage.getItem('email'),
            password: localStorage.getItem('password'),
            openTOU:false,
            wrongLogin: false
        }
    }*/

    login = (e?: React.FormEvent<HTMLFormElement>) => {
        if(e) {
            e.preventDefault();
        }
        const history = this.props.history;
        //var email = ReactDOM.findDOMNode(this.refs.email).value;
        //var password = ReactDOM.findDOMNode(this.refs.password).value;
        const email = this.state.email;
        const password = this.state.password;
        console.log("Logging in with: ", email);
        //const { history } = this.props;
        Auth.login(email,password, () => {
            console.log("Goto album");
            history.replace('album');
        }, () => {
            this.setState({wrongLogin:true});
        })
    }

    componentDidMount() {
        if(this.state.email != null && this.state.password != null)
        {
            console.log("Got email and password in state while mounting");
            this.login();
        }
        _paq.push(["setCustomUrl", "https://samtidsbild.se/welcome"]);
        _paq.push(["trackPageView", "Welcome"]);
    }

    openTOU = () => {
        this.setState({openTOU:!this.state.openTOU});
    }

    render = () => {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className="samtidsbild_header">Samtidsbild</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    {this.state.openTOU ? <Termsofuse openTOU={this.openTOU}/> :
                        <div className="page welcomePage">
                            <div className="welcomeMessage">Bidra med bilder till länets och vår gemensamma historia!</div>
                            <form onSubmit={this.login}>
                                <div className="email">
                                    <label htmlFor="email">E-post</label>
                                    <input type="email" autoCapitalize="none" id="email" placeholder=""
                                           value={this.state.email ? this.state.email : ""}
                                           onChange={e => this.setState({email: e.target.value})}/>
                                </div>
                                <div className="password">
                                    <label htmlFor="password">Lösenord</label>
                                    <input type="password" id="password" placeholder=""
                                           value={this.state.password ? this.state.password : ""}
                                           onChange={e => this.setState({password: e.target.value})}/>
                                </div>
                                <div
                                    className="wronglogin">{this.state.wrongLogin ? "Fel e-post eller lösenord" : ""}</div>
                                <button className="login" type="submit">Logga in</button>
                            </form>

                            <div className="registerbox">
                                <Link to="register" replace={true}>
                                    <div className="register clickable">Registrera dig!</div>
                                    {/*Har du inget konto, registrera dig*/}
                                </Link>
                                <br/>
                                <Link to="forgot" replace={true}>
                                    <div className="forgot clickable">Har du glömt ditt lösenord?</div>
                                </Link>
                                <br/>
                                <Link to="info" replace={true}>
                                    <div className="info clickable">Vad är Samtidsbild?</div>
                                </Link>
                                <div onClick={this.openTOU.bind(this)} className="TOU clickable">Användarvillkor</div>
                                <Link to="/about" replace={true}>
                                    <div className="about clickable">Om Tjänsten</div>
                                </Link>
                            </div>
                            <div className="footerlogo"><svg viewBox={"0 0 435.5 100"} className="icon" dangerouslySetInnerHTML={{__html: '<use xlink:href="#slmlogo"></use>'}}/></div>
                            <div style={{"fontSize": "0.4em", "position": "absolute", "bottom": "10px", "left":"0", "width":"100%","textAlign":"center"}}>Powered by <a href={"https://museum.link"} target={"_blank"} rel={"noreferrer"}>Museum.Link</a> App Version: 1.4.1</div>
                        </div>
                    }
                </IonContent>
        </IonPage>
        );
    }
}
export default withRouter(Welcome);
