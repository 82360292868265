// src/components/ProfilePage.js
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import BottomMenu from '../components/BottomMenu';
import $ from 'jquery';
import Icon from '../components/Icon';
import Auth from '../library/Auth';
import {IonContent, IonFooter, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import './ProfilePage.scss';

declare const _paq: any;
class ProfilePage extends React.Component<any, any> {

    public readonly state: any = {
        pwopen:false,
        password: "",
        password2: "",
        profile: {
            "email": "",
            "fname": "",
            "lname": "",
            "streetaddress": "",
            "zipcode": "",
            "area": "",
            "birthyear": "",
            "birthplace": "",
            "occupation": "",
            "country": ""
        },
        notthesame: false,
        passwordsaved: false,
        profilesaved: false,
        notlongenuff: false
    }

    constructor(props: any) {
        super(props);
    }

    openChangePW = () => {
        this.setState({pwopen:!this.state.pwopen});
    }
    updatePassword = (e: any) => {
        e.preventDefault();
        console.log("Lets update Password");
        if(this.evalPassword())
        {
            const authToken = localStorage.getItem('authToken');
            const url = "https://api.samtidsbild.museum.link/setPassword?authToken="+authToken;

            Auth.checkValid(() => {
                $.ajax({
                    type: "POST",
                    crossDomain: true,
                    url: url,
                    dataType: 'json',
                    cache: false,
                    data: {"password": this.state.password},
                    success: (data) => {
                        console.log("Result: ", data);
                        this.setState({passwordsaved: true});
                        localStorage.setItem('password', this.state.password);
                    },
                    error: (xhr, status, err) => {
                        console.error(url, status, err.toString());
                    }
                });
            });
        }

    }
    evalPassword = () => {
        if( this.state.password.length > 7 &&
            this.state.password != "" &&
            this.state.password2 != "" &&
            this.state.password == this.state.password2)
        {
            console.log("Passwords match");
            return true;
        }
        return false;
    }

    logout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        const history = this.props.history;
        history.replace('welcome');
    }

    componentDidMount = () => {
        _paq.push(["setCustomUrl", "https://samtidsbild.se/profile"]);
        _paq.push(["trackPageView", "Profile"]);
        const authToken = localStorage.getItem('authToken');
        const url = "https://api.samtidsbild.museum.link/getUser?authToken="+authToken;


        Auth.checkValid(() => {
            $.ajax({
                type: "GET",
                crossDomain: true,
                url: url,
                dataType: 'json',
                cache: false,
                success: (data: any) => {
                    console.log("DATA: ",data);
                    //localStorage.setItem("profile", data);
                    //this.setState({profile:data});
                    const profile = this.state.profile;
                    profile.email           = data['o:email'];
                    profile.fname           = data['o:setting']['userprofile_name'];
                    profile.lname           = data['o:setting']['userprofile_surname'];
                    profile.streetaddress   = data['o:setting']['userprofile_streetname'];
                    profile.zipcode         = data['o:setting']['userprofile_postcode'];
                    profile.area            = data['o:setting']['userprofile_area'];
                    profile.birthyear       = data['o:setting']['userprofile_birthyear'];
                    profile.birthplace      = data['o:setting']['userprofile_birthplace'];
                    profile.occupation      = data['o:setting']['userprofile_occupation'];
                    profile.why             = data['o:setting']['userprofile_why'];
                    this.setState({profile});
                },
                error: (xhr, status, err) => {
                    console.error(url, status, err.toString());
                }
            });
        });


        /*this.refs.password_savedok.addEventListener("animationend", () => {
            this.setState({passwordsaved:false});
        });

        this.refs.profile_savedok.addEventListener("animationend", () => {
            this.setState({profilesaved:false});
        });*/
    }
    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if(prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname === this.props.match.path) {
            this.componentDidMount();
        }
    }

    save = () => {
        console.log(this.state.profile);
        const authToken = localStorage.getItem('authToken');
        const url = "https://api.samtidsbild.museum.link/setUser?authToken=" + authToken;

        Auth.checkValid(() => {
            $.ajax({
                type: "POST",
                crossDomain: true,
                url: url,
                dataType: 'json',
                data: this.state.profile,
                cache: false,
                success: (data) => {
                    console.log("Result: ", data);
                    this.setState({profilesaved: true}, () => {
                        setTimeout(() => {
                            this.setState({profilesaved: false});
                        }, 2500);
                    });
                },
                error: (xhr, status, err) => {
                    console.error(url, status, err.toString());
                }
            });
        });
    }


    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className="samtidsbild_header">Samtidsbild</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen >
                    <div className="page profilePage">
                        <h3>Dina uppgifter</h3>
                        <table className={"widescreen"}>
                            <tbody>
                            <tr><td>Email</td><td className={"disabled"}>{this.state.profile.email}</td></tr>
                            <tr><td>Förnamn</td><td className={"editable"}><input type="text" value={this.state.profile.fname}          onChange={(e) => { const profile=this.state.profile; profile.fname=e.target.value;          this.setState({profile: profile})}}/></td></tr>
                            <tr><td>Efternamn</td><td className={"editable"}><input type="text" value={this.state.profile.lname}          onChange={(e) => { const profile=this.state.profile; profile.lname=e.target.value;          this.setState({profile: profile})}}/></td></tr>
                            <tr><td>Bostadsadress</td><td className={"editable"}><input type="text" value={this.state.profile.streetaddress}  onChange={(e) => { const profile=this.state.profile; profile.streetaddress=e.target.value;  this.setState({profile: profile})}}/></td></tr>
                            <tr><td>Postnummer</td><td className={"editable"}><input type="text" value={this.state.profile.zipcode}        onChange={(e) => { const profile=this.state.profile; profile.zipcode=e.target.value;        this.setState({profile: profile})}}/></td></tr>
                            <tr><td>Postort</td><td className={"editable"}><input type="text" value={this.state.profile.area}           onChange={(e) => { const profile=this.state.profile; profile.area=e.target.value;           this.setState({profile: profile})}}/></td></tr>
                            <tr><td>Födelseår</td><td className={"editable"}><input type="text" value={this.state.profile.birthyear}      onChange={(e) => { const profile=this.state.profile; profile.birthyear=e.target.value;      this.setState({profile: profile})}}/></td></tr>
                            <tr><td>Födelseplats</td><td className={"editable"}><input type="text" value={this.state.profile.birthplace}     onChange={(e) => { const profile=this.state.profile; profile.birthplace=e.target.value;     this.setState({profile: profile})}}/></td></tr>
                            <tr><td>Arbete</td><td className={"editable"}><input type="text" value={this.state.profile.occupation}     onChange={(e) => { const profile=this.state.profile; profile.occupation=e.target.value;     this.setState({profile: profile})}}/></td></tr>
                            <tr><td>Varför fotograferar du?</td><td className={"editable"}><textarea value={this.state.profile.why}    onChange={(e) => { const profile=this.state.profile; profile.why=e.target.value;    this.setState({profile: profile})}}/></td></tr>
                            </tbody>
                        </table>
                        <div className={"portraitScreen"}>
                            <label>Email<div className={"disabled"}>{this.state.profile.email}</div></label>
                            <label>Förnamn<input type="text" value={this.state.profile.fname}               onChange={(e)       => { const profile=this.state.profile; profile.fname=e.target.value;          this.setState({profile: profile})}}/></label>
                            <label>Efternamn<input type="text" value={this.state.profile.lname}             onChange={(e)       => { const profile=this.state.profile; profile.lname=e.target.value;          this.setState({profile: profile})}}/></label>
                            <label>Bostadsadress<input type="text" value={this.state.profile.streetaddress} onChange={(e)       => { const profile=this.state.profile; profile.streetaddress=e.target.value;  this.setState({profile: profile})}}/></label>
                            <label>Postnummer<input type="text" value={this.state.profile.zipcode}          onChange={(e)       => { const profile=this.state.profile; profile.zipcode=e.target.value;        this.setState({profile: profile})}}/></label>
                            <label>Postort<input type="text" value={this.state.profile.area}                onChange={(e)       => { const profile=this.state.profile; profile.area=e.target.value;           this.setState({profile: profile})}}/></label>
                            <label>Födelseår<input type="text" value={this.state.profile.birthyear}         onChange={(e)       => { const profile=this.state.profile; profile.birthyear=e.target.value;      this.setState({profile: profile})}}/></label>
                            <label>Födelseplats<input type="text" value={this.state.profile.birthplace}     onChange={(e)       => { const profile=this.state.profile; profile.birthplace=e.target.value;     this.setState({profile: profile})}}/></label>
                            <label>Arbete<input type="text" value={this.state.profile.occupation}           onChange={(e)       => { const profile=this.state.profile; profile.occupation=e.target.value;     this.setState({profile: profile})}}/></label>
                            <label>Varför fotograferar du?<textarea value={this.state.profile.why}          onChange={(e)    => { const profile=this.state.profile; profile.why=e.target.value;            this.setState({profile: profile})}}/></label>
                        </div>

                        <div className={"savedok_container"}>
                            <div className="savedok">Sparat</div>
                            <button className="registerOK savedok_btn" onClick={this.save}>{this.state.profilesaved ? "Sparat": "Spara"}</button>
                        </div>
                        <div onClick={this.openChangePW} className={"clickable"} style={{margin: "10px 0"}}>Byt lösenord</div>
                        <div style={{display: this.state.pwopen ? "block":"none"}}>
                            <form action="" method="">
                                <table className={"widescreen"}>
                                    <tbody>
                                    <tr><td>Nytt lösenord</td><td className={"editable"}> <input name="password" type="password" id="password" onChange={(e) => {this.setState({password: e.target.value, notlongenuff:(e.target.value.length < 8)});}}/></td></tr>
                                    {this.state.notlongenuff ?
                                        <tr><td><div className="notlongenuff">Lösenordet måste vara minst 8 tecken
                                            långt</div></td></tr> : <></>}
                                    <tr><td>Skriv lösenord igen</td><td className={"editable"}><input name={"repeatPassword"} type="password" id="password2" onChange={(e) => {this.setState({password2: e.target.value, notthesame: (e.target.value != this.state.password)});}}/></td></tr>
                                    </tbody>
                                </table>
                                <div className={"portraitScreen"}>
                                    <div><label>Nytt lösenord <input name="password" type="password" id="changePassword" onChange={(e) => {this.setState({password: e.target.value, notlongenuff:(e.target.value.length < 8)});}}/></label></div>
                                    {this.state.notlongenuff ?
                                        <div className="notlongenuff">Lösenordet måste vara minst 8 tecken
                                            långt</div> : ""}
                                    <div><label>Skriv lösenord igen <input name={"repeatPassword"} type="password" id="changePassword2" onChange={(e) => {this.setState({password2: e.target.value, notthesame: (e.target.value != this.state.password)});}}/></label></div>
                                </div>
                                {this.state.notthesame ?
                                    <div className="notthesame">Lösenorden måste vara identiska</div> : <></>}
                                <div className={"savedok_container"}>
                                    <button name="submit" className="registerOK savedok_btn" onClick={this.updatePassword}>{this.state.passwordsaved ? "Sparat": "Uppdatera lösenord"}</button>
                                </div>
                            </form>
                        </div>
                        <div className="clickable" onClick={this.logout} style={{margin: "10px 0"}}>Logga ut</div>
                        <div className="footerlogo"><svg viewBox={"0 0 435.5 100"} className="icon" dangerouslySetInnerHTML={{__html: '<use xlink:href="#slmlogo"></use>'}}/></div>
                        <br/><br/>
                    </div>
                </IonContent>
                <BottomMenu/>
            </IonPage>
        );
    }
}

export default withRouter(ProfilePage)
