// src/components/RegisterPage.js
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import BottomMenu from '../components/BottomMenu';
import Termsofuse from '../components/Termsofuse';
import Progress from '../components/Progress';
import Auth from '../library/Auth';
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import './RegisterPage.scss'

declare const _paq: any;
class RegisterPage extends React.Component<any, any> {
    public readonly state: any = {
        page:0,
        showExplanation:false,
        dataMissing: false,
        openTOU:false,
        email: "",
        email_good: true,
        password: "",
        password2: "",
        password_good: true,
        fname: "",
        fname_good: true,
        lname: "",
        lname_good: true,
        streetaddress: "",
        streetaddress_good: true,
        tou_good: true,
        zipcode: "",
        zipcode_good: true,
        area: "",
        area_good: true,
        birthyear: 0,
        birthyear_good: true,
        birthplace: "",
        birthplace_good: true,
        occupation: "",
        occupation_good: true,
        why: null,
        notlongenuff: false,
        notthesame: false,
        existingEmail: false,
        error: false,
    }
    constructor(props: any, context: any) {
        super(props, context);
    }

    componentDidMount = () => {
        _paq.push(["setCustomUrl", "https://samtidsbild.se/register"]);
        _paq.push(["trackPageView", "Register"]);
    }
    isEmail = (email: string) => {
        return /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    }
    register = (e: any) => {
        e.preventDefault();

        /*var data = {
            "email": ReactDOM.findDOMNode(this.refs.email).value,
            "password": ReactDOM.findDOMNode(this.refs.password).value,
            "password2": ReactDOM.findDOMNode(this.refs.password2).value,
            "fname": ReactDOM.findDOMNode(this.refs.fname).value,
            "lname": ReactDOM.findDOMNode(this.refs.lname).value,
            "streetaddress": ReactDOM.findDOMNode(this.refs.streetaddress).value,
            "zipcode": ReactDOM.findDOMNode(this.refs.zipcode).value,
            "area": ReactDOM.findDOMNode(this.refs.area).value,
            "birthyear": ReactDOM.findDOMNode(this.refs.birthyear).value,
            "birthplace": ReactDOM.findDOMNode(this.refs.birthplace).value,
            "occupation": ReactDOM.findDOMNode(this.refs.occupation).value,
            "acceptedTOU": ReactDOM.findDOMNode(this.refs.terms).value
        };*/
        const data = {
            "email": this.state.email,
            "password": this.state.password,
            "password2": this.state.password2,
            "fname": this.state.fname,
            "lname": this.state.lname,
            "streetaddress": this.state.streetaddress,
            "zipcode": this.state.zipcode,
            "area": this.state.area,
            "birthyear": this.state.birthyear,
            "birthplace": this.state.birthplace,
            "occupation": this.state.occupation,
            "acceptedTOU": this.state.acceptedTOU,
            "why": this.state.why
        };

        //Begin final-check
        let allGood = true;
        const passwords = true;

        allGood = this.firstPageOK() && this.secondPageOK();


        //console.log(data.password, data.password.length, data.password2, data.password2.length);
        /*if(data.password.length < 4 && data.password2.length < 4) { allGood = false; passwords = false; }
        if(data.password !== data.password2) { allGood = false; passwords = false; }
        if(this.isEmail(data.email) === false) { allGood = false; }
        if(data.fname.length < 1) { allGood = false; }
        if(data.lname.length < 1) { allGood = false; }
        if(data.streetaddress.length < 1) { allGood = false; }
        if(data.zipcode.length < 1 || data.zipcode.length > 10) { allGood = false; }
        if(data.area.length < 1) { allGood = false; }
        if(data.birthyear < 1900) { allGood = false; }
        if(data.birthplace.length < 1) { allGood = false; }
        if(data.occupation.length < 1) { allGood = false; }
        if(!data.acceptedTOU) { allGood = false; }*/
        //this.setState({dataMissing: !allGood});

        console.log(data,"!ALLGOOD: ",allGood,passwords);
        if(allGood)
        {
            console.log("All good!");
            // Send it away!
            const url = "https://api.samtidsbild.museum.link/register";
            $.ajax({
                type: "POST",
                crossDomain: true,
                url: url,
                dataType: 'json',
                cache: false,
                data: data,
                success: (data: any) => {
                    console.log(data);
                    if(data.ok)
                    {
                        _paq.push(["setCustomUrl", "https://samtidsbild.se/registerSucessful"]);
                        _paq.push(["trackPageView", "Register Successful"]);

                        this.nextPage();
                        localStorage.setItem('authToken', data.data['o:authToken']);
                        localStorage.setItem('email', this.state.email);
                        localStorage.setItem('password', this.state.password);
                    }
                    else
                    {
                        if(data.old)
                        {
                            this.setState({existingEmail:true});
                        }
                        console.error("Could not create user");
                        if(data.msg === "Register fail") {
                            window.alert("Kunde inte registrera användare");
                        }
                    }
                },
                error: (xhr, status, err) => {
                    console.error(url, status, err.toString());
                    this.setState({error: true});
                }
            });

        } else {
            console.log("No good!");
        }

    }
    firstPageOK = () => {
        console.log("Check if First page is OK");
        const statuses = [];
        statuses.push(this.fnameOK());
        statuses.push(this.lnameOK());
        statuses.push(this.emailOK());
        statuses.push(this.passwordOK());
        //console.log("Statuses: ", statuses);
        return  statuses.indexOf(false)===-1
    }
    fnameOK = () => {
        const fname_good = (this.state.fname.length > 0);
        this.setState({fname_good});
        console.log("Fname OK: ", fname_good);
        return fname_good;
    }
    lnameOK = () => {
        const lname_good = (this.state.lname.length > 0);
        this.setState({lname_good});
        return lname_good;
    }
    emailOK = () => {
        const email_good = this.isEmail(this.state.email);
        this.setState({email_good});
        console.log("CHEKCKING EMAIL: ", this.state.email, email_good);
        return email_good;
    }
    passwordOK = () => {
        const password_good = (this.state.password.length > 7 && this.state.password === this.state.password2);
        this.setState({password_good});
        return password_good;
    }
    secondPageOK = () => {
        console.log("Check if Second page is OK");
        const statuses = [];
        statuses.push(this.streetaddressOK());
        statuses.push(this.zipcodeOK());
        statuses.push(this.areaOK());
        statuses.push(this.birthyearOK());
        statuses.push(this.birthplaceOK());
        statuses.push(this.occupationOK());
        statuses.push(this.touOK());
        const allOK = statuses.indexOf(false)===-1
        this.setState({dataMissing: !allOK});
        return allOK;
    }
    streetaddressOK = () => {
        const streetaddress_good = (this.state.streetaddress.length > 0);
        this.setState({streetaddress_good});
        return streetaddress_good;
    }
    zipcodeOK = () => {
        const zipcode_good = (this.state.zipcode.length > 1 && this.state.zipcode.length < 10);
        this.setState({zipcode_good});
        return zipcode_good;
    }
    areaOK = () => {
        const area_good = (this.state.area.length > 0);
        this.setState({area_good});
        return area_good;
    }
    birthyearOK = () => {
        const birthyear_good = (this.state.birthyear >= 1900);
        this.setState({birthyear_good});
        return birthyear_good;
    }
    birthplaceOK = () => {
        const birthplace_good = (this.state.birthplace.length > 0);
        this.setState({birthplace_good});
        return birthplace_good;
    }
    occupationOK = () => {
        const occupation_good = (this.state.occupation.length > 0);
        this.setState({occupation_good});
        return occupation_good;
    }
    touOK = () => {
        const tou_good = (this.state.acceptedTOU === "ja");
        this.setState({tou_good});
        return tou_good;
    }

    nextPage = () => {
        console.log("Next page");
        if(this.state.page < 2 && this.firstPageOK())
        {
            this.setState({page:(this.state.page + 1)})
        }
    }
    previousPage = () => {
        if(this.state.page > 0)
        {
            this.setState({page:(this.state.page - 1)}, () => { console.log(this.state)})
        }
    }
    cancel = () => {
        const history = this.props.history;
        history.replace("/welcome");
        console.log("Cancel register");
    }

    openTOU = () => {
        this.setState({openTOU:!this.state.openTOU});
    }

    setPassword = (e: any) => {
        const password = e.target.value;
        //let notlongenuff = this.state.notlongenuff;
        //let notlongenuff = (password.length < 5);
        /*if(password.length < 5)
        {
            notlongenuff = true;
        }
        else
        {
            notlongenuff = false;
        }*/
        this.setState({password:password,notlongenuff:(password.length < 8)});
    }

    setPassword2 = (e: any) => {
        const password2 = e.target.value;
        this.setState({password2:password2, notthesame: (password2 != this.state.password)});
    }


    showLivingExplanation = () => {
        this.setState({showExplanation:!this.state.showExplanation});
    }
    render() {
        //console.log("PAGE: ",this.state.page);

        let explanation = null;
        //console.log(this.state.showExplanation);
        if(this.state.showExplanation)
        {
            explanation = <div className="explanation">Museet samlar fotografier tagna av personer som bor i Stockholms län. Men bilderna kan vara fotograferade var som helst i världen. Museet kan också behöva nå dig i framtiden om någon vill använda din bild. Uppgifterna om födelseår, -plats och yrke hjälper museet att tolka dina bilder. Vi tar olika sorters bilder vid olika tidpunkter i livet.</div>;
        }

        let tou = null;
        if(this.state.openTOU)
        {
            console.log("TOU!");
            //tou = <div><div onClick={this.openTOU.bind(this)}>Close</div><Termsofuse/></div>;
            tou = <Termsofuse openTOU={this.openTOU}/>;
        }
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className="samtidsbild_header">Samtidsbild</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen >
                    <div className="registerPage page" style={{"display": this.state.openTOU ? "none": "block"}}>
                        <div className="subpage" style={{"display": (this.state.page == 0 ? "block" : "none")}}>
                            <h3 className="uploadProgress">Registrera dig - steg 1 av 2</h3>
                            <form>
                                <div className="input">
                                    <label>Namn<input type="text" className={this.state.fname_good ? "good" : "bad"}
                                                      placeholder="Namn" onChange={(e) => {
                                        this.setState({fname: e.target.value});
                                    }} onBlur={this.fnameOK}/></label>
                                </div>
                                <div className="input">
                                    <label>Efternamn <input type="text"
                                                            className={this.state.lname_good ? "good" : "bad"}
                                                            placeholder="Efternamn" onChange={(e) => {
                                        this.setState({lname: e.target.value})
                                    }} onBlur={this.lnameOK}/></label>

                                </div>
                                <div className="input">
                                    <label>E-post <input type="email"
                                                         className={this.state.email_good ? "good" : "bad"}
                                                         placeholder="info@example.com" onChange={(e) => {
                                        this.setState({email: e.target.value, existingEmail: false})
                                    }} onBlur={this.emailOK}/></label>
                                </div>
                                <div className="input">
                                    <label>Lösenord <input type="password"
                                                           className={this.state.password_good ? "good" : "bad"}
                                                           placeholder="Lösenord" onChange={this.setPassword}
                                                           onBlur={this.passwordOK}/></label>
                                    {this.state.notlongenuff ?
                                        <div className="notlongenuff">Lösenordet måste vara minst 8 tecken
                                            långt</div> : ""}
                                </div>
                                <div className="input">
                                    <label>Upprepa lösenord <input type="password"
                                                                   className={this.state.password_good ? "good" : "bad"}
                                                                   placeholder="Lösenord"
                                                                   onChange={this.setPassword2}
                                                                   onBlur={this.passwordOK}/></label>
                                    {this.state.notthesame ?
                                        <div className="notthesame">Lösenorden måste vara identiska</div> : ""}
                                </div>
                            </form>
                            <button className="previousPage button" onClick={this.cancel}>Avbryt</button>
                            <button className="nextPage" onClick={this.nextPage}>Nästa sida</button>
                        </div>
                        <div className="subpage" style={{"display": (this.state.page == 1 ? "block" : "none")}}>
                            <h3 className="uploadProgress">Registrera dig - steg 2 av 2</h3>
                            <p onClick={this.showLivingExplanation} className="whyMuseum">Varför behöver museet veta
                                var jag bor?</p>
                            {explanation}
                            <form>
                                <div className="input">
                                    <label>Gatuadress <input type="text"
                                                             className={this.state.streetaddress_good ? "good" : "bad"}
                                                             placeholder="Gatuadress" onChange={(e) => {
                                        this.setState({streetaddress: e.target.value})
                                    }} onBlur={this.streetaddressOK}/></label>
                                </div>
                                <div className="input">
                                    <label>Postnummer <input type="text"
                                                             className={this.state.zipcode_good ? "good" : "bad"}
                                                             placeholder="123 45" onChange={(e) => {
                                        this.setState({zipcode: e.target.value})
                                    }} onBlur={this.zipcodeOK}/></label>
                                </div>
                                <div className="input">
                                    <label>Postort <input type="text"
                                                          className={this.state.area_good ? "good" : "bad"}
                                                          placeholder="Postort" onChange={(e) => {
                                        this.setState({area: e.target.value})
                                    }} onBlur={this.areaOK}/></label>
                                </div>
                                <div className="input">
                                    <label>Födelseår <input type="year"
                                                            className={this.state.birthyear_good ? "good" : "bad"}
                                                            placeholder="1900" onChange={(e) => {
                                        this.setState({birthyear: e.target.value})
                                    }} onBlur={this.birthyearOK}/></label>
                                </div>
                                <div className="input">
                                    <label>Födelseplats <input type="text"
                                                               className={this.state.birthplace_good ? "good" : "bad"}
                                                               placeholder="Födelseplats" onChange={(e) => {
                                        this.setState({birthplace: e.target.value})
                                    }} onBlur={this.birthplaceOK}/></label>
                                </div>
                                <div className="input">
                                    <label>Yrke <input type="text"
                                                       className={this.state.occupation_good ? "good" : "bad"}
                                                       placeholder="Yrke" onChange={(e) => {
                                        this.setState({occupation: e.target.value})
                                    }} onBlur={this.occupationOK}/></label>
                                </div>
                                <div className={"input"}>
                                    <label>Varför fotograferar du? <textarea
                                                               onChange={(e) => {
                                                                   this.setState({why: e.target.value})
                                                               }}/></label>
                                </div>
                                <div className="input terms">
                                    <div className={this.state.tou_good ? "good" : "bad"}>
                                        <label htmlFor="tou" style={{width: "auto"}}>Jag godkänner </label>
                                        <input id="tou" type="checkbox" value="ja"
                                               onChange={() => {
                                                   this.setState({"acceptedTOU": this.state.acceptedTOU == "ja" ? "nej":"ja"}, this.touOK)
                                               }}/>
                                        <span className="clickable" onClick={this.openTOU}>användarvillkoren</span>
                                    </div>
                                </div>
                            </form>
                            <button className="previousPage" onClick={this.previousPage}>Föregående</button>
                            <button className="register" onClick={this.register}>Registrera</button>
                            <div
                                className="dataMissing">{this.state.dataMissing ? "Data saknas eller är felformaterad" : ""}</div>
                            <div className="existingEmail"
                                 style={{display: (this.state.existingEmail ? "block" : "none")}}>Denna mailaddress
                                är redan registrerad. Om du har glömt ditt lösenord kan du <Link to="forgot" replace={true}>återställa det här.</Link></div>
                        </div>
                        <div className="subpage" style={{"display": (this.state.page === 2 ? "block" : "none")}}>
                            <h2>Grattis! Du är nu registrerad på Samtidsbild</h2>
                            <p>När du laddar upp bilder blir de en del av vår historia och lagras för framtiden i
                                Stockholms läns museums digitala fotoarkiv Samtidsbild.</p>
                            <p>Du ska nu ha fått ett registeringsmail till den e-postadress du registrerade med.</p>
                            <Link to="welcome" replace={true}>
                                <button className="registerOK">OK</button>
                            </Link>
                        </div>
                    </div>
                    {this.state.openTOU ? <Termsofuse openTOU={this.openTOU}/> : <></>}
                </IonContent>
            </IonPage>
        );
        //<label htmlFor="terms">Jag har läst och godkänner</label>
    }
}

export default withRouter(RegisterPage)
