import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Welcome from "./pages/Welcome";
import AlbumPage from "./pages/AlbumPage";
import RegisterPage from "./pages/RegisterPage";
import InfoPage from "./pages/InfoPage";
import ForgotpasswordPage from "./pages/ForgotPassword";
import AboutPage from "./pages/AboutPage";
import ProfilePage from "./pages/ProfilePage";
import UploadPage from "./pages/UploadPage";
import SplashScreen from "./pages/SplashScreen";
import UploadGratz from "./pages/UploadGratz";
import { install } from "resize-observer";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/base.scss';
import './theme/mediaQuery.scss';

setupIonicReact({
  swipeBackEnabled: false,
});
const resize = () => {
  if (typeof window !== "undefined") {
    install();
  }
}
resize();

const Samtidsbild: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/">
          <SplashScreen />
        </Route>
        <Route exact path="/welcome">
          <Welcome />
        </Route>
        <Route exact path="/album">
          <AlbumPage/>
        </Route>
        <Route exact path="/album/:id">
          <AlbumPage/>
        </Route>
        <Route exact path="/register">
          <RegisterPage/>
        </Route>
        <Route exact path="/info">
          <InfoPage/>
        </Route>
        <Route exact path="/forgot">
          <ForgotpasswordPage/>
        </Route>
        <Route exact path="/about">
          <AboutPage/>
        </Route>
        <Route exact path="/profile">
          <ProfilePage/>
        </Route>
        <Route exact path="/upload">
          <UploadPage/>
        </Route>
        <Route exact path="/upload/album">
          <UploadPage/>
        </Route>
        <Route exact path="/upload/camera">
          <UploadPage/>
        </Route>
        <Route exact path="/upload/gratz">
          <UploadGratz/>
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default Samtidsbild;
