// src/components/UploadGratz.js
import React from 'react';
import { Link, withRouter} from 'react-router-dom';
import BottomMenu from '../components/BottomMenu';
import Icon from '../components/Icon';
import './UploadGratz.scss'
import {IonContent, IonFooter, IonHeader, IonToolbar, IonPage, IonTitle} from "@ionic/react";

declare const _paq:any;
class UploadGratz extends React.Component<any, any> {
    componentDidMount = () => {
        console.log("Ùpload Congratz loaded");
        _paq.push(["setCustomUrl", "https://samtidsbild.se/uploadGratz"]);
        _paq.push(["trackPageView", "UploadGratz"]);
        console.log("After push");
    }
    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className="samtidsbild_header">Samtidsbild</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen >
                    <div className="page uploadGratzPage">
                        <div className="gratz">
                            <h3>Grattis!</h3>
                            <p>Din bild är nu del av länets och vår gemensamma historia.</p>
                            <p>Ladda gärna upp fler bilder!</p>
                            <p>Klicka på hus-ikonen för att se dina uppladdade bilder.</p>
                            <p>
                                <a target="_blank" rel="noreferrer" href="https://stockholmslansmuseum.se/samlingar/samtidsbild/">Dina och andras bilder hittar du här</a>
                            </p>
                        </div>
                        <div className="footerlogo"><svg viewBox={"0 0 435.5 100"} className="icon" dangerouslySetInnerHTML={{__html: '<use xlink:href="#slmlogo"></use>'}}/></div>
                    </div>
                </IonContent>
                <BottomMenu/>
            </IonPage>
        );
    }
}

export default withRouter(UploadGratz);
