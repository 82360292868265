// src/components/SplashScreen.js
import React from 'react';
import { withRouter } from 'react-router-dom';
import './SplashScreen.scss'
import { StatusBar } from '@capacitor/status-bar';
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";

declare const _paq: any;
class SplashScreen extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        if(StatusBar) {
            StatusBar.setBackgroundColor({color: "#ffc44e".toString()}).catch(()=>{console.error("StatusBar is not implemented in Web")});
        }
    }
    componentDidMount() {
        const history = this.props.history;

        if(localStorage.getItem('splashDone') === "true")
        {
            history.replace('/welcome');
        }
        else
        {
            setTimeout(function() {
                //console.log("Doing the deed");
                history.replace('/welcome');
                localStorage.setItem('splashDone', "true");
            }, 5000);
            _paq.push(["setCustomUrl", "https://samtidsbild.se/splash"]);
            _paq.push(["trackPageView", "Splash"]);
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className="samtidsbild_header">Samtidsbild</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="splashscreen">
                    <div className="collage_image"></div>
                </IonContent>
            </IonPage>
        );
    }
}

export default withRouter(SplashScreen)
