// src/components/ForgotpasswordPage.js
import React from 'react';
import { Link, withRouter} from 'react-router-dom';
import $ from 'jquery';
import Auth from '../library/Auth';
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import './ForgotPassword.scss';

declare const _paq: any;
class ForgotpasswordPage extends React.Component<any, any> {

    public readonly state: any = {
        email_validated: 0,
        email: localStorage.getItem('email') || "",
        emailsent: false,
        code: "",
        codeVerified: false,
        codeEntered: false,
        authToken: "",
        password: "",
        password2: "",
        passwordsaved: false,
        notthesame: false,
        notlongenuff: false,
        notValidEmail: false
    }

    constructor(props: any, context: any) {
        super(props, context);
    }

    componentDidMount = () => {
        _paq.push(["setCustomUrl", "https://samtidsbild.se/forgot"]);
        _paq.push(["trackPageView", "Forgot"]);
    }

    isEmail = (email: string) => {
        return /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    }

    validate = (e: any) => {
        const input = e.target.value;
        /*let good = 0;
        if(input == "lol@lol.lol") {
            good = 1;
        }
        else { good = 2;}*/
        this.setState({email: input});
    }
    submit = (e: any) => {
        console.log("Resetting password for: ", this.state.email);
        e.preventDefault();
        //const url = "https://samtidsbild.ablm.se/index.php/LoginReg/resetSend?reset_email="+this.state.email;
        const url = "https://api.samtidsbild.museum.link/forgotPassword";
        if(this.state.email !== "" && this.isEmail(this.state.email)) {
            $.ajax({
                type: "POST",
                crossDomain: true,
                url: url,
                cache: false,
                data: {"email":this.state.email},
                success: (data) => {
                    console.log("Result: ",data);
                    this.setState({emailsent:true});
                },
                error: (xhr, status, err) => {
                    this.setState({emailsent:false});
                    console.error(url, status, err.toString());
                }
            });
        } else {
            this.setState({notValidEmail: true})
        }
    }
    verifyCode = () => {
        console.log("Verify code: ", this.state.code);
        const url = "https://api.samtidsbild.museum.link/verifyForgotPassword";

        $.ajax({
            type: "POST",
            crossDomain: true,
            url: url,
            cache: false,
            data: {"code":this.state.code, "email": this.state.email},
            success: (data) => {
                data = JSON.parse(data);
                console.log("Result: ",data);
                if(data.ok) {
                    this.setState({codeEntered: true, codeVerified:true, authToken: data.authToken});
                } else {
                    this.setState({codeEntered: true, codeVerified:false});
                }
            },
            error: (xhr, status, err) => {
                this.setState({codeEntered: true, codeVerified:false});
                console.error(url, status, err.toString());
            }
        });
    }
    updatePassword = (e: any) => {
        e.preventDefault();
        if(this.evalPassword())
        {
            const authToken = this.state.authToken;
            const url = "https://api.samtidsbild.museum.link/setPassword?authToken="+authToken;

            console.log("URL: ", url);
            $.ajax({
                type: "POST",
                crossDomain: true,
                url: url,
                dataType: 'json',
                cache: false,
                data: {"password": this.state.password},
                success: (data) => {
                    console.log("Result: ", data);
                    if (data.jupp) {
                        this.setState({passwordsaved: true}, () => {
                            console.log("Setting passwordsaved = true", this.state);
                        });
                        localStorage.setItem('email', this.state.email);
                        localStorage.setItem('password', this.state.password);
                    } else {
                        alert("Lösenord kunde inte sparas");
                    }
                },
                error: (xhr, status, err) => {
                    console.error(url, status, err.toString());
                }
            });
        }

    }
    evalPassword = () => {
        if( this.state.password.length > 7 &&
            this.state.password != "" &&
            this.state.password2 != "" &&
            this.state.password == this.state.password2)
        {
            console.log("Passwords match");
            return true;
        }
        return false;
    }

    render() {
        /*let email_validated = "";
        if(this.state.email_validated == 1)
        {
            email_validated = "validated";
        }
        else if(this.state.email_validated == 2)
        {
            email_validated = "problematic";
        }*/
        let content = <></>;
        if(!this.state.emailsent && !this.state.codeVerified && !this.state.passwordsaved) {
            content = <div>
                <h3 className="title">Byta ditt lösenord</h3>
                <form action="" method="">
                    <label><p className="tinytext">Skriv in den e-postadress som du använde när du skapade kontot</p>
                        <input name="email" type="email" id="email" placeholder="" value={this.state.email ? this.state.email : ""} onChange={this.validate.bind(this)}/>
                    </label>
                    {this.state.notValidEmail ? <div>Ej giltig e-postadress.</div> : <></>}
                    <button onClick={this.submit}>Skicka</button>
                </form>
                <Link to="/welcome" replace={true}><button>Avbryt</button></Link>
            </div>;
        } else if(this.state.emailsent && !this.state.codeVerified && !this.state.passwordsaved) {
            content = <div>
                <p>Kolla din e-post, där finns en kod för att återställa ditt lösenord.</p>
                <p>Om du inte hittar mailet kolla i din skräppost.</p>
                <label>Skriv in koden här: <input type={"text"} name={"code"} id={"code"} onChange={(e) => { this.setState({code: e.target.value}) }} /></label>
                {this.state.codeEntered && !this.state.codeVerified ? <div>Fel kod</div> : <></>}
                <button onClick={this.verifyCode}>Skicka</button>
                <Link to="/welcome" replace={true}><button>Tillbaka</button></Link>
            </div>;
        } else if(this.state.emailsent && this.state.codeVerified && !this.state.passwordsaved) {
            content = <div>
                <p>Återställningskod verifierad, var god fyll i nytt lösenord</p>
                <table className={"widescreen"}>
                    <tbody>
                    <tr><td>Nytt lösenord</td><td className={"editable"}> <input name="password" type="password" id="resetPassword" onChange={(e) => {this.setState({password: e.target.value, notlongenuff:(e.target.value.length < 8)});}}/></td></tr>
                    {this.state.notlongenuff ?
                        <tr><td><div className="notlongenuff">Lösenordet måste vara minst 8 tecken
                            långt</div></td></tr> : <></>}
                    <tr><td>Skriv lösenord igen</td><td className={"editable"}><input name={"repeatPassword"} type="password" id="resetPassword2" onChange={(e) => {this.setState({password2: e.target.value, notthesame: (e.target.value != this.state.password)});}}/></td></tr>
                    </tbody>
                </table>
                {this.state.notthesame ?
                    <div className="notthesame">Lösenorden måste vara identiska</div> : <></>}
                <div className={"portraitScreen"}>
                    <div><label>Nytt lösenord <input name="password" type="password" id="password" onChange={(e) => {this.setState({password: e.target.value, notlongenuff:(e.target.value.length < 8)});}}/></label></div>
                    {this.state.notlongenuff ?
                        <div className="notlongenuff">Lösenordet måste vara minst 8 tecken
                            långt</div> : ""}
                    <div><label>Skriv lösenord igen <input name={"repeatPassword"} type="password" id="password2" onChange={(e) => {this.setState({password2: e.target.value, notthesame: (e.target.value != this.state.password)});}}/></label></div>
                </div>
                <button onClick={this.updatePassword}>Spara</button>
                <Link to="/welcome" replace={true}><button>Tillbaka</button></Link>
            </div>;
        } else if(this.state.emailsent && this.state.codeVerified && this.state.passwordsaved) {
            content = <div>
                <div>Nya lösenordet är sparat.</div>
                <Link to="/welcome" replace={true}><button>Tillbaka till startsidan</button></Link>
            </div>;
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className="samtidsbild_header">Samtidsbild</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen >
                    <div className="page forgotPasswordPage">
                        <br/>
                        {content}
                    </div>
                </IonContent>
            </IonPage>
        );
    }
}

export default withRouter(ForgotpasswordPage)
